import React from "react"
import { Col, Row } from "reactstrap"

const PricingDetail = ({ label, price }) => {
    return (
        <Row className="d-flex justify-content-between my-1">
            <Col>{label}</Col>
            <Col className="text-right">${price?.toFixed(2)}</Col>
        </Row>
    )
}

export default PricingDetail

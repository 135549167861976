import * as Yup from "yup"

export const FIELDS_NAME = {
    TYPE: "type",
    SIZE: "size",
    PRICE: "price",
    NAME: "name",
    MODEL: "model",
    STATUS: "status",
}

export const initialValues = {
    [FIELDS_NAME.MODEL]: [],
    [FIELDS_NAME.NAME]: "",
    [FIELDS_NAME.PRICE]: "",
    [FIELDS_NAME.SIZE]: [],
    [FIELDS_NAME.TYPE]: "",
    [FIELDS_NAME.STATUS]: "ACTIVE",
}

export const validationSchema = Yup.object({
    [FIELDS_NAME.PRICE]: Yup.number("Enter numbers only")
        .required("Enter valid amount")
        .test("", "Price must be between 1 and 9 digits", item => {
            let num = item?.toString()?.length
            return num <= 9 && num >= 1
        }),
    [FIELDS_NAME.NAME]: Yup.string()
        .required("Enter trailer name")
        .min(6, "Enter trailer  name between 6 to 250 characters")
        .max(250, "Enter trailer name between 6 to 250 characters"),

    [FIELDS_NAME.MODEL]: Yup.array()
        .min(1, "At least one model must be selected")
        .required("Select model"),
    [FIELDS_NAME.STATUS]: Yup.string().required("Select status"),
    [FIELDS_NAME.TYPE]: Yup.string().required("Select type"),
    [FIELDS_NAME.SIZE]: Yup.array()
        .min(1, "At least one size must be selected")
        .required("Select size"),
})

export const objectContainsKey = (object, key) => {
    return typeof object === "object" && object && object[key] !== undefined
}

export const convertNameIntoInitials = name => {
    if (!name) {
        return ""
    }
    var parts = name?.split(" ")
    var initials = ""
    for (var i = 0; i < parts.length; i++) {
        if (i < 2)
            if (parts[i].length > 0 && parts[i] !== "") {
                initials += parts[i][0]
            }
    }
    return initials?.toUpperCase()
    // var initial = "";
    // if (name.trim()){
    //     initial = name?.trim()[0]?.toUpperCase();
    // }
    // return initial
}

export const getCommaSeparatedNumber = amount => {
    return new Intl.NumberFormat("en-US").format(amount)
}

export const getFormattedAmountWithCurrency = (amount, currency = "AED") => {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency || "USD",
        minimumFractionDigits: 0,
    })
    return formatter.format(amount)
}

export const escapeHTMLElementsFromMessage = message => {
    //todo places in util helper
    var htmlEscapes = {
        "<": "&lt;",
        ">": "&gt;",
    }
    // Regex containing the keys listed immediately above.
    var htmlEscaper = /[<>]/g
    // Escape a string for HTML interpolation.
    return message.replace(htmlEscaper, function (match) {
        return htmlEscapes[match]
    })
}

export const getObjectByKeys = (
    arr,
    key = "Id",
    deleteKey = null,
    addKeys = null
) => {
    let obj = {}
    arr.forEach(val => {
        obj[val[key]] = val
        if (deleteKey) {
            delete obj[val[key]][deleteKey]
        }
        if (addKeys) {
            obj[val[key]] = {
                ...obj[val[key]],
                ...addKeys,
            }
        }
    })
    return obj
}

export const getIdsFromData = (data, key = "Id") => {
    return data.map(item => item[key])
}

export const getPhoneNumber = data => {
    if (data && data.CountryCode && data.PhoneNumber) {
        return `${data.CountryCode} ${data.PhoneNumber}`
    }
    return "-"
}

export const prepareTableColumnState = columnData => {
    let obj = {}
    Object.keys(columnData).forEach(key => {
        obj[columnData[key]] = {
            title: columnData[key],
            isShown: true,
        }
    })

    return obj
}

export const emailMasking = email => {
    var maskid = ""
    var myemailId = email
    var prefix = myemailId.substring(0, myemailId.lastIndexOf("@"))
    var postfix = myemailId.substring(myemailId.lastIndexOf("@"))

    for (var i = 0; i < prefix.length; i++) {
        if (i == 0 || i == prefix.length - 1) {
            maskid = maskid + prefix[i].toString()
        } else {
            maskid = maskid + "•"
        }
    }
    maskid = maskid + postfix
    return maskid
}

export const updateObjCheckBox = (item, dataState) => {
    let checkBoxList = [...dataState]
    const selectedElement = checkBoxList.filter(el => el.id === item.id)
    if (selectedElement.length > 0) {
        checkBoxList = checkBoxList.filter(el => el.id != item.id)
    } else {
        checkBoxList.push(item)
    }
    return checkBoxList
}

export const getRandomBetweenTwoNum = (min, max) => {
    if (!min || !max) return
    let minNum = Math.ceil(min)
    let maxNum = Math.floor(max)
    return Math.floor(Math.random() * (maxNum - minNum) + minNum)
}

export const arrayItemsInList = (data, price = false) => {
    let tempArr = [...data]
    if (price) {
        tempArr = tempArr.map(el => `$${el.toFixed(2)}`)
    }
    let modifiedList = tempArr.join(", ")
    return modifiedList
}

export const getLimitedItems = (arr = [], start, end) => {
    let newArray = arr.slice(start, end)
    return newArray
}
export const getSelectBoxOptionsFromConstants = (obj = {}) => {
    let keys = Object.keys(obj)

    if (!keys?.length) return

    let options = keys.map((item, index) => ({
        label: item.split("_").join(" "),
        value: item.split("_").join(""),
        isDisabled: false,
    }))

    return options
}

export const getCommaSeparatedStrings = (arr = []) => {
    if (!arr?.length) return

    return arr.toString()
}

export const removeSpaceUnderscoreCharacterFromString = (str = "") => {
    if (!str) return
    let data = str
    let newStr = data.replace(/[^a-zA-Z.]/g, "") // remove non-letter characters, except period
    return `${newStr}`
}

export const truncateString = (string = "", maxLength) => {
    if (!string || !maxLength) return
    return string.length > maxLength
        ? `${string.substring(0, maxLength)}…`
        : string
}

// demo the above function
// alert(truncateString("Hello World", 4))
export const validateOptionSelect = (
    newOption = {},
    previousSelectedOptions = [],
    matchKey = ""
) => {
    return Boolean(
        previousSelectedOptions?.find(
            item => item[matchKey] == newOption[matchKey]
        )
    )
}

export const makeOptionsDisabled = (checkingList, options) => {
    // return
    const tempData =
        Object.keys(options).length && options.data.length
            ? [...options?.data]
            : []
    tempData.map(el => {
        checkingList?.map(innerEl => {
            if (innerEl.skuNo == el.skuNo) {
                el.isDisabled = true
            }
        })
    })
    return tempData
}
export const convertToSelectOptions = (obj = {}) => {
    let keys = Object.keys(obj)

    if (!keys?.length) return

    let options = keys.map((item, index) => ({
        id: `${index}_${item}`,
        label: `${obj?.getDisplayTextKey(obj[item])}`,
        value: obj[item],
    }))

    return options
}
export const getUID = () => Date.now().toString(36) + Math.random().toString(36)
export const hasData = obj => {
    if (!obj) return
    return Boolean(Object.keys(obj).length)
}

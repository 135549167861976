import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import { Col, Form, FormGroup, Label, Row } from "reactstrap"
import toast from "react-hot-toast"

import {
    initialValues,
    TimerConfigSettings_validationSchema,
} from "./FormConfig"
import { AddTimerConfiguration, GetTimerConfiguration } from "api/api.service"
import { TextInput } from "components/TextInput"
import FormikErrorText from "components/FormikErrorText/FormikErrorText"
import { Button } from "components/Button"
import { InventorySettings } from "constants"

const TimerConfigSettings = () => {
    const [buttonloading, setButtonLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [timerConfigSettings, setTimerConfigSettings] = useState({
        ...initialValues.TimerConfigSettings,
    })

    useEffect(() => {
        getTimerConfiguration()
    }, [])

    let getTimerConfiguration = async () => {
        try {
            setIsLoading(true)
            let res = await GetTimerConfiguration({
                inventorySettingsType: InventorySettings.Timer,
            })
            setTimerConfigSettings({
                timeInDays: Number(res.inventorySettingsTypeValue),
            })
        } catch (err) {
        } finally {
            setIsLoading(false)
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { ...timerConfigSettings },
        validationSchema: TimerConfigSettings_validationSchema,
        onSubmit: values => {
            handleSubmitTimerConfigSettings(values)
        },
    })

    let handleSubmitTimerConfigSettings = async data => {
        try {
            let payload = {
                inventorySettingsType: InventorySettings.Timer,
                inventorySettingsTypeValue: data.timeInDays.toString(),
            }

            setButtonLoading(true)
            let res = await AddTimerConfiguration(payload)
            toast.success("Timer configurations has been set successfully !")
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setButtonLoading(false)
        }
    }

    return (
        <Row className="mt-4">
            <Col sm={12} xs={12} md={12} lg={12} className="form-container">
                <Form onSubmit={formik.handleSubmit}>
                    <div>
                        <FormGroup className=" user-validation-handler" row>
                            <Label
                                for="lead-time-in-days"
                                xs={3}
                                sm={6}
                                md={5}
                                lg={4}
                                className="label-Container user-validation-handler pe-0 pe-md-3"
                            >
                                Timer Configuration (days) :
                            </Label>
                            <Col sm={6} md={5} xs={9} className="ps-0 ps-md-3">
                                <>
                                    <TextInput
                                        className="text-input"
                                        type="number"
                                        name="timeInDays"
                                        id="time-in-days"
                                        placeholder="Enter no of days"
                                        value={formik.values.timeInDays}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        min={1}
                                    />
                                    <FormikErrorText
                                        formikInstance={formik}
                                        fieldName={"timeInDays"}
                                        errorClasses={"error fs-10"}
                                    />
                                </>
                            </Col>
                        </FormGroup>
                    </div>

                    <div className="finance-setting-bottom-button-container">
                        <Button
                            isLoading={buttonloading}
                            type="submit"
                            size="lg"
                            className="gt-btn-grad-primary table-bottom-btn setting-finance-btn"
                            title={<div>Set Days</div>}
                        />
                    </div>
                </Form>
            </Col>
        </Row>
    )
}

export default TimerConfigSettings

export const APP_ROUTES = {
    SPLASH: "/get-started",
    LOGIN: "/auth/login",
    FORGET_PASSWORD: "/auth/forgot-password",
    FORGET_VERIFY_CODE: "/auth/forget-verify-code",
    RESET_PASSWORD: "/auth/reset-password",
    DASHBOARD: "/dashboard",
    USER_PROFILE: "/profile",
    SETTINGS: "/settings",
    INVENTORY: "/inventory",
    INVENTORY_REQUEST: "/inventory-request",
    ORDER_MANAGEMENT: "/order-management",
    INVENTORY_MANAGEMENT: "/inventory-management",
    LOCATION: "/locations",
    USER_MANAGEMENT: "/user-management",
    VENDOR_MANAGEMENT: "/vendor-management",
    VENDOR_MANAGEMENT_DETAILS: "/vendor-management/:vendorId",
    FAQS: "/faqs",
    ADMIN_LOCATION_WAREHOUSE: "/locations/warehouse",
    ADMIN_LOCATION_DEPARTMENT: "/locations/station",
    ADMIN_LOCATION_DEPARTMENT_ITEM_CATALOG:
        "/locations/station/:departmentId/item-catalog",
    ADMIN_ORDER_MANAGEMENT: "/admin-order-management",
    INVENTORY_WAREHOUSE: "/inventory/warehouse",
    INVENTORY_DEPARTMENT: "/inventory/station",
    INVENTORY_WAREHOUSE_GI: "/inventory/warehouse/gi",
    INVENTORY_WAREHOUSE_CHEMICALS: "/inventory/warehouse/chemicals",
    INVENTORY_WAREHOUSE_TOOLS: "/inventory/warehouse/tools",
    INVENTORY_DEPARTMENT_GI: "/inventory/station/gi",
    INVENTORY_DEPARTMENT_CHEMICALS: "/inventory/station/chemicals",
    INVENTORY_DEPARTMENT_TOOLS: "/inventory/station/tools",
    INVENTORY_REQUEST_DEPARTMENT: "/inventory-request/station",
    INVENTORY_REQUEST_PULLBACK: "/inventory-request/transfer-inventory",
    INVENTORY_REQUEST_SCRAP: "/inventory-request/scrap",
    // Trailer
    ORDERED_TRAILERS: "/ordered-trailers",
    // Configurator
    CONFIGURATOR: "/configurator",
    CONFIGURATOR_TRAILERS: "/configurator/trailers",
    CONFIGURATOR_TRAILER_SETTINGS: "/configurator/trailer/:trailerId",
    CONFIGURATOR_TRAILER_SETTINGS_BASE_FEATURES:
        "/configurator/trailer/:trailerId/base-features",
    CONFIGURATOR_TRAILER_SETTINGS_BASE_FEATURES_ITEMS:
        "/configurator/trailer/:trailerId/base-features/:categoryId/items",
    CONFIGURATOR_TRAILER_SETTINGS_PACKAGES:
        "/configurator/trailer/:trailerId/packages",
    CONFIGURATOR_TRAILER_SETTINGS_CUSTOMIZATION:
        "/configurator/trailer/:trailerId/customization",
    CONFIGURATOR_TRAILER_SETTINGS_CUSTOMIZATION_ITEMS:
        "/configurator/trailer/:trailerId/customization/:categoryId/items",
    // CONFIGURATOR_TRAILER_SETTINGS_CUSTOMIZATION_BASE_FEATURES:
    //     "/configurator/trailer/:trailerId/settings/customization/base-features",

    CONFIGURATOR_TRAILER_SETTINGS_EQUIPMENTS:
        "/configurator/trailer/:trailerId/equipment",
    CONFIGURATOR_TRAILER_SETTINGS_EQUIPMENT_ITEMS:
        "/configurator/trailer/:trailerId/equipment/:categoryId/items",
    CONFIGURATOR_TRAILER_SETTINGS_SUMMARY:
        "/configurator/trailer/:trailerId/summary",
}

export const getRoute = (route, obj = {}) => {
    if (Object.keys(obj).length) {
        let objectKeys = Object.keys(obj)
        objectKeys.forEach(item => {
            route = route.replace(new RegExp(/:([\d\w?])+/, "i"), match => {
                let formattedMatchedValue =
                    match[match.length - 1] === "?"
                        ? match.slice(1, match.length - 1)
                        : match.slice(1)
                return obj[formattedMatchedValue]
            })
        })

        return route
    }

    return route
}

import * as Yup from "yup"

export const FIELDS_NAME = {
    NAME: "name",
    PRICE: "price",
    DESCRIPTION: "description",
    STATUS: "status",
}

export const initialValues = {
    [FIELDS_NAME.DESCRIPTION]: "",
    [FIELDS_NAME.PRICE]: "",
    [FIELDS_NAME.NAME]: "",
    [FIELDS_NAME.STATUS]: "ACTIVE",
}

export const validationSchema = Yup.object({
    [FIELDS_NAME.NAME]: Yup.string().required("Enter item name"),

    [FIELDS_NAME.DESCRIPTION]: Yup.string().required("Enter item description"),

    [FIELDS_NAME.STATUS]: Yup.string().required("Select status"),
    [FIELDS_NAME.PRICE]: Yup.number("Enter numbers only")
        .required("Enter valid price")
        .test("", "Price must be between 1 and 9 numbers", item => {
            let num = item?.toString()?.length
            return num <= 9 && num >= 1
        }),
})

import React, { useState } from "react"
import { Col, Row } from "reactstrap"
import { useHistory } from "react-router-dom"
import toast from "react-hot-toast"

import eyeIcon from "../../../assets/images/icons/eye-icon.svg"
import settingsIcon from "../../../assets/images/icons/settings-icon.svg"
import editIcon from "../../../assets/images/icons/edit-icon.svg"

import customStyles from "../../Common/AutoCompleteStyles"
import ActiveInactiveSwitch from "../CommonUI/ActiveInactiveSwitch/ActiveInactiveSwitch"
import { DataTable } from "../../../components/DataTable"
import { useLocalPaginatedRequest } from "../../../hooks/useLocalPaginatedRequest"
import { Pagination } from "../../../components/Pagination"
import { GetPackagesListing, UpdatePackage } from "../../../api/api.service"
import { APP_ROUTES, getRoute } from "../../../helpers/routeHelpers"
import { SearchBox } from "../../../components/SearchBox"
import { Button } from "../../../components/Button"
import { AutoComplete } from "../../../components/AutoComplete"
import { ConfiguratorPackagesStatus } from "../../../constants"
import { convertToSelectOptions } from "../../../utils/commonUtils"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { convertDateTime, dateTimeFormat } from "utils/dateFormatUtils"
import ManagePackages from "./ManagePackages"

const COLUMNS = {
    ITEM_NAME: "Package name",
    PACKAGE_PRICE: "Price",
    STATUS: "Status",
    ACTIONS: "Actions",
}
const DATA_KEY = "data"
const TOTAL_KEY = "total"

const Packages = ({}) => {
    const { trailerId } = useParams()
    const history = useHistory()
    const [statusFilters, setStatusFilters] = useState(null)
    const [selection, setSelection] = useState({})

    const {
        data,
        isFetching,
        page,
        searchText,
        pageSize,
        total,
        handleSearchChange,
        request,
        handlePageClick,
        onChangePageSize,
        setTableDataChangeLoading,
        tableDataChangeLoading,
        setData,
    } = useLocalPaginatedRequest({
        requestFn: GetPackagesListing,
        params: {
            trailerId: trailerId,
            ...(statusFilters?.value && { status: statusFilters?.value }),
        },
        deps: [statusFilters],
        dataKey: DATA_KEY,
        totalKey: TOTAL_KEY,
    })

    const [eventsType, setEventsType] = useState({
        isManage: null,
        isUpdate: null,
        isDetails: null,
    })

    const handleInitManage = obj => {
        setEventsType(prev => ({ ...prev, isManage: true }))
        setSelection(obj)
    }

    const handleInitViewDetails = obj => {
        setSelection(obj)
        setEventsType(prev => ({ ...prev, isDetails: true, isManage: true }))
    }

    const handleSelectStatusOption = option => {
        setStatusFilters(option)
    }

    const handleStatusChange = async (currStatus, id) => {
        let status =
            currStatus === ConfiguratorPackagesStatus.Active
                ? ConfiguratorPackagesStatus.Inactive
                : ConfiguratorPackagesStatus.Active
        let dataClone = [...data]
        dataClone.map(el => {
            if (el?.id == id) el.status = status
        })
        setData(dataClone)
        let payload = {
            status: status,
        }
        try {
            setTableDataChangeLoading(true)
            let res = await UpdatePackage(id, payload)
            toast.success("Package status has been updated !")
        } catch (err) {
            toast.error(err.message)
            let dataClone = [...data]
            dataClone.map(el => {
                if (el?.id == id) el.status = currStatus
            })
            setData(dataClone)
        } finally {
            setTableDataChangeLoading(false)
        }
    }

    return (
        <>
            <div className="mb-3 trailer-container">
                <div className="d-flex align-items-center justify-content-between admin-inventory-management-general-inventory-responsiveness trailer-btn-container">
                    <div />

                    <Button
                        className={`header-button headerButtonResponsive`}
                        title="Add Package"
                        onClick={() => handleInitManage({})}
                    />
                </div>
            </div>
            {/* <Row className="py-2">
                <Col sm="12" md="6" lg="4"></Col>
                <Col sm="12" md="6" lg="4"></Col>

                <Col sm="12" md="6" lg="4" align="right">
                    <Button
                        className={`header-button`}
                        title="Add Package"
                        onClick={() => handleInitManage({})}
                    />
                </Col>
            </Row> */}
            <div className="mb-3">
                <div className="d-flex align-items-center justify-content-between admin-inventory-management-general-inventory-responsiveness ">
                    <div className="status-search-con wh-im-dpgi-status  me-2 ">
                        <AutoComplete
                            value={statusFilters}
                            isClearable
                            onChange={val => handleSelectStatusOption(val)}
                            isSearchable={false}
                            placeholder="Select status"
                            customStyles={customStyles.AdminAppSelectStyles}
                            classNamePrefix="status-header-search-admin"
                            options={convertToSelectOptions(
                                ConfiguratorPackagesStatus
                            )}
                        />
                    </div>
                    <div className="ad-location-search-box">
                        <SearchBox
                            inputClasses="vendor-search-input input-search"
                            labelClass="d-none"
                            placeholder="Search"
                            searchIcon={true}
                            searchIconClass="vendor-table-search-icon"
                            conClass="vendor-table-search-inner-class"
                            searchText={searchText}
                            onChange={handleSearchChange}
                        />
                    </div>
                </div>
            </div>
            {/* <Row className="py-2">
                <Col sm="12" md="6" lg="4">
                    <div className="min-250-w margin-top-handle">
                        <AutoComplete
                            value={statusFilters}
                            isClearable
                            onChange={val => handleSelectStatusOption(val)}
                            isSearchable={false}
                            placeholder="Select status"
                            customStyles={customStyles.AdminAppSelectStyles}
                            classNamePrefix="status-header-search-admin"
                            options={convertToSelectOptions(
                                ConfiguratorPackagesStatus
                            )}
                        />
                    </div>
                </Col>
                <Col sm="12" md="6" lg="4"></Col>

                <Col sm="12" md="6" lg="4">
                    <SearchBox
                        inputClasses="custom-input-search custom-input-search-height"
                        labelClass="d-none"
                        placeholder="Search"
                        searchIcon={true}
                        searchIconClass="custom-input-search-icon"
                        conClass={`search-input-container-class`}
                        searchText={searchText}
                        onChange={handleSearchChange}
                    />
                </Col>
            </Row> */}
            <Row>
                <Col md={12}>
                    <DataTable
                        data={data}
                        tableClasses="package-table"
                        loading={isFetching}
                        // className="vendor-data-container mt-3"
                        config={[
                            {
                                title: COLUMNS.ITEM_NAME,
                                className: "",
                                render: data => data.packageName || "-",
                            },

                            {
                                title: COLUMNS.PACKAGE_PRICE,
                                className: "",
                                render: data =>
                                    Number(data?.price).toFixed(2) || "-",
                            },

                            // {
                            //     title: COLUMNS.DATE_EDITED,
                            //     className: "",
                            //     render: data => {
                            //         return convertDateTime({
                            //             date: data.updatedAt,
                            //             customFormat:
                            //                 dateTimeFormat.casitaCustomDateFormat,
                            //             dateOnly: true,
                            //         })
                            //     },
                            // },

                            {
                                title: COLUMNS.STATUS,
                                className: "",
                                render: data => (
                                    <ActiveInactiveSwitch
                                        isDisable={tableDataChangeLoading}
                                        id={data.id}
                                        name={`${data.id}`}
                                        onChange={e =>
                                            handleStatusChange(
                                                data?.status,
                                                data?.id
                                            )
                                        }
                                        isChecked={
                                            data?.status ==
                                            ConfiguratorPackagesStatus.Active
                                        }
                                    />
                                ),
                            },

                            {
                                title: COLUMNS.ACTIONS,

                                render: data => (
                                    <div className="d-flex gap-2 pointer">
                                        <img
                                            src={eyeIcon}
                                            onClick={() =>
                                                handleInitViewDetails(data)
                                            }
                                        />

                                        <img
                                            src={editIcon}
                                            onClick={() =>
                                                handleInitManage(data)
                                            }
                                        />
                                    </div>
                                ),
                            },
                        ]}
                    />
                </Col>
                <Pagination
                    currentPage={page}
                    totalData={total}
                    onPageClick={handlePageClick}
                />
            </Row>

            <ManagePackages
                isDetailView={eventsType.isDetails}
                isOpen={eventsType.isManage}
                id={selection?.id}
                onSuccess={request}
                onToggleModal={() => {
                    setSelection({})
                    setEventsType(prev => ({
                        ...prev,
                        isManage: false,
                        isDetails: false,
                    }))
                }}
            />
        </>
    )
}

export default Packages

import React, { useState, useEffect } from "react"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"

import "./style.scss"
import { DataTable } from "../../../../../components/DataTable"
import { TextInput } from "../../../../../components/TextInput"
import { AutoComplete } from "../../../../../components/AutoComplete"
import { Button } from "../../../../../components/Button"
import {
    GetItemsListing,
    AddItemCatalog,
    UpdateItemCatalog,
} from "../../../../../api/api.service"
import DeleteIcon from "../../../../../assets/images/icons/finance-vendor-mang-del-icon.svg"
import AddNewItemSelectStyling from "./AddNewItemSelectStyling"
import Config from "../../../../../Config"
import {
    validateOptionSelect,
    makeOptionsDisabled,
} from "../../../../../utils/commonUtils"
import { validateZero } from "../../../../../utils/formUtils"
import { Col, Row } from "reactstrap"

const COLUMNS = {
    SKU_NO: "SKU No.",
    ITEM_NAME: "Item Name",
    QUANTITY: "Quantity",
    ACTION: "Action",
}

const LIMIT = 100

const AddNewItemModal = ({
    modalCloseHandler,
    listingRequestHandler,
    selectedData = [],
    alreadyHadItems = [],
    currentItemsCount = 0,
    catalogId = "",
    departmentName = "",
}) => {
    let controller = new AbortController()
    let signal = controller.signal
    const { departmentId } = useParams()

    const [isAddingRequest, setIsAddingRequest] = useState()
    const [selectedItems, setSelectedItems] = useState(null)
    const [previousSelectedItems, setPreviousSelectedItems] = useState([
        ...selectedData,
    ])

    const [itemsListing, setItemsListing] = useState({
        data: [],
        fetching: true,
        page: 1,
        isDataAvailable: true,
    })

    useEffect(() => {
        itemsListing.page && handleGetItemsListing(itemsListing.page)

        return () => {
            controller.abort()
        }
    }, [itemsListing.Q])

    const handleSelectOption = option => {
        if (
            typeof option != "object" ||
            validateOptionSelect(option, previousSelectedItems, "id") ||
            !Object.keys(option)
        )
            return
        setPreviousSelectedItems(prev => {
            return [
                ...prev,
                { ...option, requiredQuantity: "", hasError: false },
            ]
        })
    }
    const handleSearch = (value = "", callback) => {
        if (!value) return

        callback(prev => {
            return {
                ...prev,
                data: [],
                page: 1,
                Q: value,
            }
        })
    }

    const handleGetItemsListing = page => {
        let itemsIdArr, test

        if (previousSelectedItems.length > 0 || alreadyHadItems.length > 0) {
            let combineArr = [...alreadyHadItems, ...previousSelectedItems]

            itemsIdArr = combineArr?.map(item => item.itemsId)
        }

        setItemsListing(prev => {
            return {
                ...prev,
                fetching: true,
            }
        })
        GetItemsListing(
            {
                limit: LIMIT,
                ...(itemsListing.Q && { Q: itemsListing.Q }),
                Page: page,
            },
            signal
        )
            .then(res => {
                setItemsListing(prev => ({
                    ...prev,
                    fetching: false,
                    page: page + 1,
                    isDataAvailable: res?.data?.length == LIMIT,
                    data: (() => {
                        test = res?.data?.map(item => ({
                            ...item,
                            value: item.id,
                            label: `${item.skuNo} - ${item.itemName}`,
                        }))

                        if (
                            previousSelectedItems.length ||
                            alreadyHadItems.length
                        ) {
                            test = test.filter(
                                item => !itemsIdArr.includes(item.id)
                            )
                        }

                        return [...test]
                    })(),
                }))
            })
            .finally(() => {
                setItemsListing(prev => {
                    return {
                        ...prev,
                        fetching: false,
                    }
                })
            })
    }

    const handleSetItemQuantity = (evt, targetObj, index) => {
        const modifiedValue = evt.target.value
        // .replace(/[^0-9]*/g, "")
        let indexOfTargetObj = index
        let stateCopy = [...previousSelectedItems]
        let refObj = (stateCopy[indexOfTargetObj] = {
            ...targetObj,
        })

        refObj.requiredQuantity = modifiedValue

        setPreviousSelectedItems(stateCopy)
    }

    const handleDeleteSelectedItem = (id = "", sku) => {
        if (!id) return

        let filteredData = previousSelectedItems.filter(obj => obj.id != id)
        setPreviousSelectedItems([...filteredData])
        if (filteredData.length < 1) {
            setSelectedItems(null)
        }
        itemsListing?.data.map(el => {
            if (el.skuNo === sku) {
                el.isDisabled = false
            }
        })
    }

    const handleSubmitItemCatalog = async () => {
        let request
        let bodyData = {}

        if (validateZero(previousSelectedItems, "requiredQuantity")) {
            toast.error("Item's quantity can't be 0 !")
            return
        }

        let formattedItemsList = previousSelectedItems.map(obj => {
            return {
                itemsId: obj.itemsId || obj.id,
                quantity: Math.abs(obj.requiredQuantity),
            }
        })

        if (currentItemsCount == 0) {
            bodyData = {
                departmentId: Number(departmentId),
                items: formattedItemsList,
            }
            request = async () => await AddItemCatalog(bodyData)
        } else {
            bodyData = {
                items: formattedItemsList,
            }
            request = async () => await UpdateItemCatalog(catalogId, bodyData)
        }

        try {
            setIsAddingRequest(true)
            let res = await request()
            toast.success("Items has been added successfully !")
            listingRequestHandler()
            setSelectedItems(null)
            setPreviousSelectedItems("")
            setItemsListing([])
            modalCloseHandler()
        } catch (err) {
        } finally {
            setIsAddingRequest(false)
        }
    }

    return (
        <>
            <h3
                className=" inv-req-popup-heading px-3"
                style={{
                    fontWeight: "bold",
                    color: "black",
                }}
            >
                Station: {departmentName}
            </h3>

            <div className="pt-3 pb-4  d-flex align-items-center px-3">
                <span
                    className="font-size-13 "
                    style={{
                        width: "100px",
                        fontWeight: "bold",
                        color: "black",
                    }}
                >
                    Add Item
                </span>
                <div style={{ width: "250px" }}>
                    <AutoComplete
                        placeholder="Search Item"
                        classNamePrefix="add-new-req"
                        value={selectedItems}
                        customStyles={AddNewItemSelectStyling}
                        onInputChange={val =>
                            handleSearch(val, setItemsListing)
                        }
                        options={makeOptionsDisabled(
                            previousSelectedItems,
                            itemsListing
                        )}
                        onChange={val => handleSelectOption(val)}
                        closeMenuOnSelect={true}
                        isLoading={itemsListing?.fetching}
                        handleBlur={() =>
                            setItemsListing(prev => {
                                return {
                                    ...prev,
                                    page: 1,
                                    Q: "",
                                }
                            })
                        }
                    />
                </div>
            </div>
            <DataTable
                data={previousSelectedItems}
                className="view-detail-inv-req-table  height-scroll-table w-100"
                tableClasses="add-new-request-table"
                config={[
                    {
                        title: COLUMNS.SKU_NO,
                        render: data => {
                            return data?.skuNo
                        },
                    },
                    {
                        title: COLUMNS.ITEM_NAME,
                        render: data => {
                            return data?.itemName
                        },
                    },
                    {
                        title: COLUMNS.QUANTITY,
                        render: (data, idx) => {
                            return (
                                <div className="">
                                    <TextInput
                                        onChange={evt =>
                                            handleSetItemQuantity(
                                                evt,
                                                data,
                                                idx
                                            )
                                        }
                                        className="view-detail-inv-req-p-ip-con me-4 me-md-5"
                                        labelClass="d-none"
                                        type="number"
                                        step="0.000001"
                                        min={0.1}
                                        inputClass="view-detail-inv-req-p-ip"
                                        value={data.requiredQuantity}
                                        placeholder="Quantity"
                                    />
                                </div>
                            )
                        },
                    },

                    {
                        title: COLUMNS.ACTION,
                        render: data => {
                            return (
                                <img
                                    onClick={() =>
                                        handleDeleteSelectedItem(
                                            data.id,
                                            data.skuNo
                                        )
                                    }
                                    className="view-detail-modal-delete"
                                    src={DeleteIcon}
                                />
                            )
                        },
                    },
                ]}
            />
            <div className="text-center my-4">
                <Button
                    disabled={!Boolean(previousSelectedItems?.length)}
                    isLoading={isAddingRequest}
                    size="lg"
                    className="gt-btn-grad-primary table-bottom-btn m-0 p-0 btn-alignment"
                    title="Save Changes"
                    onClick={handleSubmitItemCatalog}
                />
            </div>
        </>
    )
}

export default AddNewItemModal

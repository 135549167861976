import React from "react"
import { CardBody } from "reactstrap"

import { Button } from "components/Button"

const CancelPOItemRequest = ({
    loading = false,
    onCancelClick = () => {},
    onYesClick = () => {},
}) => {
    return (
        <div className="d-flex flex-column align-items-center  mb-4 mt-4">
            <CardBody>
                <h5 className="user-mang-modal-label">
                    Are you sure, you wants to cancel this Item Request ?
                </h5>
            </CardBody>
            <div className="d-flex justify-content-between mt-5">
                <Button
                    title="Cancel"
                    className="user-mang-modal-cancel-btn"
                    onClick={onCancelClick}
                />

                <Button
                    title="Yes"
                    className="gt-btn-grad-primary user-mang-modal-del-btn"
                    onClick={onYesClick}
                    isLoading={loading}
                />
            </div>
        </div>
    )
}

export default CancelPOItemRequest

import React, { useState, useEffect } from "react"
import { Col, Row, Form } from "reactstrap"
import { useFormik } from "formik"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"

import { TextInput } from "../../../components/TextInput"
import { Button } from "../../../components/Button"
import FormikErrorText from "../../../components/FormikErrorText/FormikErrorText"
import { FIELDS_NAME, initialValues, validationSchema } from "./FormConfig"
import ActiveInactiveSwitch from "../CommonUI/ActiveInactiveSwitch/ActiveInactiveSwitch"
import { GetSummaryDetails, UpdateSummary } from "../../../api/api.service"
import {
    InventorySettings,
    ConfiguratorSummaryStatus,
} from "../../../constants"
import FormSkeleton from "components/FormSkeleton/FormSkeleton"

const TITLES = {
    UPDATE_BTN_TEXT: "Save",
}

const Summary = () => {
    const { trailerId } = useParams()
    const [currentDownPaymentSetting, setCurrentDownPaymentSetting] = useState(
        {}
    )
    const [status, setStatus] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const formik = useFormik({
        enableReinitialze: true,
        initialValues: {
            ...initialValues,
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values)
        },
    })

    useEffect(() => {
        handleFetchDetails()
    }, [trailerId])

    const handleStatusConversion = status => {
        return status == ConfiguratorSummaryStatus.Active ? true : false
    }

    const handleSubmit = values => {
        let bodyData = {
            configuratorTrailerId: Number(trailerId),
            InventorySettingsType: InventorySettings.DownpaymentPercentage,
            inventorySettingsTypeValue:
                values[FIELDS_NAME.DOWN_PAYMENT_PERCENTAGE],
            status: values[FIELDS_NAME.STATUS],
        }

        handleUpdate(bodyData)
    }

    const handleUpdate = async data => {
        try {
            setIsLoading(true)

            let res = await UpdateSummary(currentDownPaymentSetting?.id, data)
            toast.success(
                `Down payment percentage has been updated successfully !`
            )
            handleFetchDetails()
        } finally {
            setIsLoading(false)
        }
    }

    const handleFetchDetails = async () => {
        if (!trailerId) return

        try {
            setIsFetching(true)
            let res = await GetSummaryDetails(trailerId)
            setCurrentDownPaymentSetting(res)
            formik.setValues({
                [FIELDS_NAME.DOWN_PAYMENT_PERCENTAGE]:
                    res?.inventorySettingsTypeValue,
                [FIELDS_NAME.STATUS]: res.status,
            })
            setStatus(handleStatusConversion(res.status))
        } finally {
            setIsFetching(false)
        }
    }

    const handleSetStatus = evt => {
        let { name } = evt.target
        let alteredStatus = !status
        setStatus(alteredStatus)
        formik.setFieldValue(
            name,
            alteredStatus
                ? ConfiguratorSummaryStatus.Active
                : ConfiguratorSummaryStatus.Inactive
        )
    }

    if (isFetching) {
        return (
            <div className="w-50 p-4">
                <FormSkeleton />
            </div>
        )
    }

    return (
        <div
            style={{ pointerEvents: isLoading ? "none" : "auto" }}
            className="pt-3"
        >
            <div className="d-flex justify-content-center mb-3"></div>
            <Row>
                <Col xs={12}>
                    <Form onSubmit={formik.handleSubmit}>
                        <Row className="mb-3 align-items-center">
                            <label className=" col-md-3 col-form-label clr-theme-primary">
                                Down Payment Percentage (%)
                            </label>
                            <div className="col-md-4 col-sm-12">
                                <TextInput
                                    placeholder={"Enter percentage %"}
                                    name={FIELDS_NAME.NAME}
                                    {...formik.getFieldProps(
                                        FIELDS_NAME.DOWN_PAYMENT_PERCENTAGE
                                    )}
                                />
                                <FormikErrorText
                                    formikInstance={formik}
                                    fieldName={
                                        FIELDS_NAME.DOWN_PAYMENT_PERCENTAGE
                                    }
                                />
                            </div>
                        </Row>

                        <Row className="mb-3 align-items-center">
                            <label className=" col-md-3 col-form-label clr-theme-primary">
                                Status
                            </label>
                            <div className="col-md-9">
                                <ActiveInactiveSwitch
                                    isChecked={status}
                                    name={FIELDS_NAME.STATUS}
                                    onChange={handleSetStatus}
                                />
                                <FormikErrorText
                                    formikInstance={formik}
                                    fieldName={FIELDS_NAME.STATUS}
                                />
                            </div>
                        </Row>

                        <div className="d-flex justify-content-start  mt-4">
                            <Button
                                isLoading={isLoading}
                                type={"submit"}
                                title={TITLES.UPDATE_BTN_TEXT}
                                className="header-button"
                            />
                        </div>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default React.memo(Summary)

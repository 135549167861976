export default {
    SPLASH: "Casita Trailers",
    DASHBOARD: "Dashboard",
    LOGIN: "Login",
    FORGET_PASSWORD: "Forgot Password",
    FORGET_VERIFY_CODE: "Forget Verification Code",
    RESET_PASSWORD: "Reset Password",
    SETTINGS: "Settings",
    USER_PROFILE: "User Profile",
    SETTINGS: "Settings",
    INVENTORY_REQUEST: "Inventory Request",
    INVENTORY_MANAGEMENT: "Inventory Management",
    ORDER_MANAGEMENT: "Order Management",
    ORDERED_TRAILERS: "Ordered Trailers",
    LOCATION: "Locations",
    USER_MANAGEMENT: "User Management",
    VENDOR_MANAGEMENT: "Vendor Management",
    CONFIGURATOR: "Configurator Settings",
    CONFIGURATOR_TRAILERS: "Configurator - Trailers",
    FAQS: "FAQ'S",
}

import React, { useState, useEffect, useRef } from "react"
import { Col, Row, Form, Input } from "reactstrap"
import { useFormik } from "formik"
import toast from "react-hot-toast"

import { Modal } from "../../../components/Modal"
import { TextInput } from "../../../components/TextInput"
import { Button } from "../../../components/Button"
import FormikErrorText from "../../../components/FormikErrorText/FormikErrorText"
import ImageGallery from "../CommonUI/ImageGallery/ImageGallery"
import {
    GetTrailersDetails,
    CreateTrailer,
    UpdateTrailer,
} from "../../../api/api.service"
import {
    initializeMediaUtil,
    uploadOnS3,
    finalizeMediaUtil,
} from "../../../utils/mediaUtils"
import { FIELDS_NAME, initialValues, validationSchema } from "./FormConfig"
import ActiveInactiveSwitch from "../CommonUI/ActiveInactiveSwitch/ActiveInactiveSwitch"
import { convertToSelectOptions } from "utils/commonUtils"
import { TrailerSize, TrailerStatus, TrailerType } from "../../../constants"
import TrailerModels from "constants/TrailerModels"
import FormSkeleton from "components/FormSkeleton/FormSkeleton"

const TITLES = {
    MODAL_ADD_TITLE: "Add new trailer",
    MODAL_UPDATE_TITLE: "Update trailer",
    MODAL_DETAILS_TITLE: "Trailer details",
    ADD_BTN_TEXT: "Add Trailer",
    UPDATE_BTN_TEXT: "Update Trailer",
    DETAILS_BTN_TEXT: "Done",
}

const handleStatusConversion = status => {
    return status == TrailerStatus.Active ? true : false
}

const handleUpdateExecution = async (id, data) => {
    let res = await UpdateTrailer(id, data)
    toast.success("Trailer has been updated successfully !")
}

const ManageTrailer = ({
    isDetailView = false,
    isOpen = false,
    onToggleModal = () => {},
    onSuccess = () => {},
    id = "",
}) => {
    const imagesRef = useRef(null)

    const [isLoading, setIsLoading] = useState(false)
    const [status, setStatus] = useState(false)
    const [trailerSize, setTrailerSize] = useState([])
    const [trailerModel, setTrailerModel] = useState([])
    const [previousImages, setPreviousImages] = useState([])
    const [isFetching, setIsFetching] = useState(false)

    const formik = useFormik({
        enableReinitialze: true,
        initialValues: {
            ...initialValues,
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values)
        },
    })

    useEffect(() => {
        handleFetchDetails()
        return () => {
            setIsLoading(false)
        }
    }, [id])

    const handleSubmit = values => {
        let bodyData = {
            name: values[FIELDS_NAME.NAME],
            model: values[FIELDS_NAME.MODEL],
            price: values[FIELDS_NAME.PRICE].toString(),
            configuratorTrailerType: values[FIELDS_NAME.TYPE],
            configuratorTrailerSizeType: values[FIELDS_NAME.SIZE],
            status: values[FIELDS_NAME.STATUS],
        }

        if (id) {
            handleUpdate(bodyData)
        } else {
            handleAdd(bodyData)
        }
    }

    const uploadedMediaIDs = async images => {
        if (images) {
            try {
                let res = await handleUploadImages(images)
                return res?.length && res.map(item => item.id)
            } finally {
            }
        }
    }

    const handleUpdate = async data => {
        const payload = {
            ...data,
        }

        let userUploadedImages = imagesRef.current.getAllImages()

        if (!userUploadedImages.length && !previousImages.length) {
            return toast.error("Upload trailer images !")
        }

        try {
            setIsLoading(true)

            if (userUploadedImages.length) {
                uploadedMediaIDs(userUploadedImages).then(async res => {
                    const apiFormatForImages = res.map(imageId => ({
                        mediaId: imageId,
                    }))
                    payload.images = [
                        ...(apiFormatForImages?.length && apiFormatForImages),
                        ...(previousImages?.length
                            ? previousImages.map(image => ({
                                  mediaId: image.media.id,
                              }))
                            : []),
                    ]

                    await handleUpdateExecution(id, payload)
                    handleModalClose()
                    onSuccess()
                })
            } else {
                payload.images = [
                    ...(previousImages?.length &&
                        previousImages.map(image => ({
                            mediaId: image.mediaId,
                        }))),
                ]

                await handleUpdateExecution(id, payload)
                handleModalClose()
                onSuccess()
            }
        } finally {
            setIsLoading(true)
        }
    }
    const handleAdd = async data => {
        let userUploadedImages = imagesRef.current.getAllImages()

        if (!userUploadedImages.length) {
            toast.error("Upload trailer images !")
            return
        }

        try {
            setIsLoading(true)
            let ids = await uploadedMediaIDs(userUploadedImages)
            let apiFormatForImages = ids.map(imageId => ({
                mediaId: imageId,
            }))
            data.images = [...(apiFormatForImages.length && apiFormatForImages)]

            let res = await CreateTrailer(data)
            toast.success("Trailer has been added successfully !")
            onSuccess()
            handleModalClose()
        } catch (error) {
        } finally {
            setIsLoading(false)
        }
    }

    const handleFetchDetails = async () => {
        if (!id) return

        try {
            setIsFetching(true)
            let res = await GetTrailersDetails(id)

            formik.setValues({
                [FIELDS_NAME.MODEL]: res?.model,
                [FIELDS_NAME.NAME]: res?.name,
                [FIELDS_NAME.PRICE]: res?.price,
                [FIELDS_NAME.SIZE]: res?.configuratorTrailerSizeType,
                [FIELDS_NAME.TYPE]: res?.configuratorTrailerType,
                [FIELDS_NAME.STATUS]: res?.status,
            })
            setStatus(handleStatusConversion(res?.status))
            setPreviousImages(res?.images)
        } finally {
            setIsFetching(false)
        }
    }

    const handleUploadImages = async images => {
        let apiCallIns
        let uploadedIds = []

        apiCallIns = images.map(item => {
            return handleMediaUpload(item)
        })

        try {
            setIsLoading(true)
            uploadedIds = (await Promise.allSettled(apiCallIns))
                .filter(item => item.status === "fulfilled")
                .map(id => id.value)
        } finally {
            setIsLoading(false)
        }

        return uploadedIds
    }

    const handleMediaUpload = async file => {
        return initializeMediaUtil(file).then(async res => {
            const credentials = res
            await uploadOnS3(file, credentials, handleImageUploadProgress)
            return await finalizeMediaUtil(credentials?.mediaId)
        })
    }

    const handleImageUploadProgress = progress => {}

    const getOptions = (optionsEntity = {}) => {
        return convertToSelectOptions(optionsEntity).map((item, ind) => {
            return (
                <option value={item.value} key={ind}>
                    {item.label}
                </option>
            )
        })
    }

    const handleModalClose = () => {
        formik.resetForm()
        setPreviousImages([])
        onToggleModal()
    }

    const handleSetStatus = evt => {
        let { name } = evt.target
        let alteredStatus = !status
        setStatus(alteredStatus)
        formik.setFieldValue(
            name,
            alteredStatus ? TrailerStatus.Active : TrailerStatus.Inactive
        )
    }

    const handleCheckboxChange = (e, fieldName) => {
        const { value } = e.target

        if (formik.values[fieldName].includes(value)) {
            formik.setFieldValue(
                fieldName,
                formik.values[fieldName].filter(option => option !== value)
            )
        } else {
            formik.setFieldValue(fieldName, [
                ...formik.values[fieldName],
                value,
            ])
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            handleModalToggling={handleModalClose}
            bodyClassName=""
            customButton={true}
            hideModalHeaderSeparator={true}
            headerClasses="header-container"
            sizeClasses="8"
            backdrop="static"
            modalTitle={
                isDetailView
                    ? TITLES.MODAL_DETAILS_TITLE
                    : id
                    ? TITLES.MODAL_UPDATE_TITLE
                    : TITLES.MODAL_ADD_TITLE
            }
            scrollable
        >
            {isFetching ? (
                <FormSkeleton />
            ) : (
                <div style={{ pointerEvents: isLoading ? "none" : "auto" }}>
                    <Row>
                        <Col xs={12}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Row className="mb-3 align-items-center">
                                    <label className=" col-md-3 col-form-label clr-theme-primary">
                                        Trailer Name
                                    </label>
                                    <div className="col-md-9">
                                        <TextInput
                                            inputClass={
                                                isDetailView && "input-readonly"
                                            }
                                            placeholder="Enter trailer name"
                                            name={FIELDS_NAME.NAME}
                                            {...formik.getFieldProps(
                                                FIELDS_NAME.NAME
                                            )}
                                        />
                                        <FormikErrorText
                                            formikInstance={formik}
                                            fieldName={FIELDS_NAME.NAME}
                                        />
                                    </div>
                                </Row>
                                <Row className="mt-4 mb-3 align-items-center">
                                    <label className=" col-md-3 col-form-label clr-theme-primary">
                                        Price ($)
                                    </label>
                                    <div className="col-md-9">
                                        <TextInput
                                            type="number"
                                            min={0}
                                            step="0.000001"
                                            inputClass={
                                                isDetailView && "input-readonly"
                                            }
                                            placeholder="Enter trailer price"
                                            name={FIELDS_NAME.PRICE}
                                            {...formik.getFieldProps(
                                                FIELDS_NAME.PRICE
                                            )}
                                        />

                                        <FormikErrorText
                                            formikInstance={formik}
                                            fieldName={FIELDS_NAME.PRICE}
                                        />
                                    </div>
                                </Row>

                                <Row className="mt-4 mb-3 align-items-center">
                                    <label className=" col-md-3 col-form-label clr-theme-primary">
                                        Model
                                    </label>

                                    <div className="col-md-9">
                                        <div className="d-flex">
                                            <Input
                                                type="checkbox"
                                                name={FIELDS_NAME.MODEL}
                                                value={TrailerModels.Standard}
                                                checked={formik.values[
                                                    FIELDS_NAME.MODEL
                                                ].includes(
                                                    TrailerModels.Standard
                                                )}
                                                onClick={e =>
                                                    handleCheckboxChange(
                                                        e,
                                                        FIELDS_NAME.MODEL
                                                    )
                                                }
                                                className={
                                                    isDetailView &&
                                                    "input-readonly-checkboxes"
                                                }
                                            />

                                            <span className="mx-2">
                                                {TrailerModels.getDisplayTextKey(
                                                    TrailerModels.Standard
                                                )}
                                            </span>

                                            <Input
                                                type="checkbox"
                                                name={FIELDS_NAME.MODEL}
                                                value={TrailerModels.Deluxe}
                                                checked={formik.values[
                                                    FIELDS_NAME.MODEL
                                                ].includes(
                                                    TrailerModels.Deluxe
                                                )}
                                                onClick={e =>
                                                    handleCheckboxChange(
                                                        e,
                                                        FIELDS_NAME.MODEL
                                                    )
                                                }
                                                className={
                                                    isDetailView &&
                                                    "input-readonly-checkboxes"
                                                }
                                            />

                                            <span className="mx-2">
                                                {TrailerModels.getDisplayTextKey(
                                                    TrailerModels.Deluxe
                                                )}
                                            </span>
                                        </div>

                                        <FormikErrorText
                                            formikInstance={formik}
                                            fieldName={FIELDS_NAME.MODEL}
                                        />
                                    </div>
                                </Row>

                                <Row className="mt-4 mb-3 align-items-center">
                                    <label className=" col-md-3 col-form-label clr-theme-primary">
                                        Size
                                    </label>
                                    <div className="col-md-9">
                                        <div className="d-flex">
                                            <Input
                                                type="checkbox"
                                                name={FIELDS_NAME.SIZE}
                                                value={TrailerSize.S15}
                                                checked={formik.values[
                                                    FIELDS_NAME.SIZE
                                                ].includes(TrailerSize.S15)}
                                                onClick={e =>
                                                    handleCheckboxChange(
                                                        e,
                                                        FIELDS_NAME.SIZE
                                                    )
                                                }
                                                className={
                                                    isDetailView &&
                                                    "input-readonly-checkboxes"
                                                }
                                            />

                                            <span className="mx-2">
                                                {TrailerSize.getDisplayTextKey(
                                                    TrailerSize.S15
                                                )}
                                            </span>

                                            <Input
                                                type="checkbox"
                                                name={FIELDS_NAME.SIZE}
                                                value={TrailerSize.S17}
                                                checked={formik.values[
                                                    FIELDS_NAME.SIZE
                                                ].includes(TrailerSize.S17)}
                                                onClick={e =>
                                                    handleCheckboxChange(
                                                        e,
                                                        FIELDS_NAME.SIZE
                                                    )
                                                }
                                                className={
                                                    isDetailView &&
                                                    "input-readonly-checkboxes"
                                                }
                                            />

                                            <span className="mx-2">
                                                {TrailerSize.getDisplayTextKey(
                                                    TrailerSize.S17
                                                )}
                                            </span>
                                        </div>

                                        <FormikErrorText
                                            formikInstance={formik}
                                            fieldName={FIELDS_NAME.SIZE}
                                        />
                                    </div>
                                </Row>

                                <Row className="mt-4 mb-3 align-items-center">
                                    <label className=" col-md-3 col-form-label clr-theme-primary">
                                        Trailer Type
                                    </label>
                                    <div className="col-md-9">
                                        <Input
                                            className={
                                                isDetailView &&
                                                "input-readonly-select"
                                            }
                                            type="select"
                                            name={FIELDS_NAME.TYPE}
                                            {...formik.getFieldProps(
                                                FIELDS_NAME.TYPE
                                            )}
                                        >
                                            {getOptions(TrailerType)}
                                        </Input>

                                        <FormikErrorText
                                            formikInstance={formik}
                                            fieldName={FIELDS_NAME.TYPE}
                                        />
                                    </div>
                                </Row>

                                <Row className="mt-4 mb-3 align-items-center">
                                    <label className=" col-md-3 col-form-label clr-theme-primary">
                                        Upload Images
                                    </label>
                                    <div className="col-md-9">
                                        <ImageGallery
                                            ref={imagesRef}
                                            previousImages={previousImages}
                                            removePreviousImages={
                                                setPreviousImages
                                            }
                                            previewOnly={isDetailView}
                                            isDetailView={isDetailView}
                                        />
                                    </div>
                                </Row>

                                <Row className="mb-3 align-items-center">
                                    <label className=" col-md-3 col-form-label clr-theme-primary">
                                        Status
                                    </label>
                                    <div className="col-md-9">
                                        <ActiveInactiveSwitch
                                            isChecked={status}
                                            isDisable={isDetailView}
                                            name={FIELDS_NAME.STATUS}
                                            onChange={handleSetStatus}
                                        />
                                        <FormikErrorText
                                            formikInstance={formik}
                                            fieldName={FIELDS_NAME.STATUS}
                                        />
                                    </div>
                                </Row>

                                <div className="d-flex justify-content-center mt-3 mt-2">
                                    <Button
                                        isLoading={isLoading}
                                        type={
                                            isDetailView ? "button" : "submit"
                                        }
                                        title={
                                            isDetailView
                                                ? TITLES.DETAILS_BTN_TEXT
                                                : id
                                                ? TITLES.UPDATE_BTN_TEXT
                                                : TITLES.ADD_BTN_TEXT
                                        }
                                        className="header-button"
                                        onClick={
                                            isDetailView && handleModalClose
                                        }
                                    />
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </div>
            )}
        </Modal>
    )
}

export default React.memo(ManageTrailer)

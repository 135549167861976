import React, { useEffect, useState } from "react"

import "./style.scss"
import { DataTable } from "components/DataTable"
import { Button } from "components/Button"
import { GetWarehouseInventoryPODetails } from "api/api.service"
import { dateTimeFormat, convertDateTime } from "utils/dateFormatUtils"
import { toast } from "react-hot-toast"

const COLUMNS = {
    PONUMBER: "P.O. Number",
    PRICE: "Price (Per unit)",
    ORDER_DATE: "Order Date",
    QUANTITY_RECEIVED: "Quantity Received",
}

const AdminInventoryViewDetail = ({
    inventoryItem = "",
    modalCloseHandler,
    footerBtnText = "Done",
}) => {
    const [data, setData] = useState([])
    const [isFetching, setIsFetching] = useState(false)

    useEffect(() => {
        const getItemsDetails = async () => {
            try {
                setIsFetching(true)
                let res = await GetWarehouseInventoryPODetails(
                    inventoryItem.itemsId
                )
                setData(res?.data)
            } catch (err) {
                // toast.error(err.message)
            } finally {
                setIsFetching(false)
            }
        }
        getItemsDetails()
    }, [])

    return (
        <>
            <h3 className="text-center inv-req-popup-heading">{`${inventoryItem?.itemName} (${inventoryItem.skuNo})`}</h3>
            <div className="p-4">
                <h4 className="inventory-description w-100 p-0">
                    Product Summary
                </h4>
                <p className="mt-1 text-center">{`${
                    inventoryItem?.description || "-"
                }`}</p>
            </div>
            <DataTable
                data={data}
                className="view-detail-inv-req-table add-req-inv-con table-body-border w-100 vertical-scroll-im"
                tableClasses="add-req-inv-table admin-im-detail-table"
                loading={isFetching}
                config={[
                    {
                        title: COLUMNS.PONUMBER,
                        render: data => {
                            return data?.poNo
                        },
                    },
                    {
                        title: COLUMNS.PRICE,
                        render: data => {
                            return `$ ${data?.unitPrice?.toFixed(2)}`
                        },
                    },
                    {
                        title: COLUMNS.ORDER_DATE,
                        render: data => {
                            return convertDateTime({
                                date: data.createdAt,
                                customFormat:
                                    dateTimeFormat.casitaCustomDateFormat,
                                dateOnly: true,
                            })
                        },
                    },
                    {
                        title: COLUMNS.QUANTITY_RECEIVED,
                        render: data => {
                            return data?.quantity
                        },
                    },
                ]}
            />
            <div className="text-center my-4 d-flex justify-content-center align-items-center">
                <Button
                    size="lg"
                    className="gt-btn-grad-primary table-bottom-btn me-2 d-flex justify-content-center align-items-center"
                    title={<div>{footerBtnText}</div>}
                    onClick={modalCloseHandler}
                />
            </div>
        </>
    )
}

export default AdminInventoryViewDetail

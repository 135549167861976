import React from "react"
import "./Style.scss"

const ActiveInactiveSwitch = ({
    id,
    isDisable = false,
    onChange,
    isChecked = null,
    name,
}) => {
    return (
        <div
            className="status-switch-container"
            style={{ cursor: isDisable ? "default" : "pointer" }}
        >
            <span>Inactive</span>
            <label className="status-switch">
                <input
                    id={id}
                    disabled={isDisable}
                    type="checkbox"
                    name={name}
                    onClick={onChange}
                    checked={isChecked}
                />
                <span
                    className="status-slider round"
                    style={{ cursor: isDisable ? "default" : "pointer" }}
                ></span>
            </label>
            <span>Active</span>
        </div>
    )
}

export default React.memo(ActiveInactiveSwitch)

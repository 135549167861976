import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import { Pagination } from "components/Pagination"
import { DataTable } from "components/DataTable"
import { TextInput } from "components/TextInput"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import { dateTimeFormat, convertDateTime } from "utils/dateFormatUtils"
import { Modal } from "components/Modal"
import PlaceOrderModal from "./PlaceOrderModal"
import { SearchBox } from "components/SearchBox"
import {
    updatePlaceOrderButton,
    CHECK_BOX_UPDATE_MODAL_ITEMS,
    updateSelectedItemsLength,
} from "store/actions"
import { OrderRequestByItemsListing } from "api/api.service"
import { updateObjCheckBox } from "utils/commonUtils"
import { Refresh } from "components/Refresh"
import { UserRoles } from "../../../../constants"

const COLUMNS = {
    SELECT_ALL: "Select All",
    REQUEST_ID: "Request Id",
    SKU: "SKU No.",
    ITEM_NAME: "Item Name",
    PREFERRED_VENDOR: "Preferred Vendor",
    QUANTITY: "Requested Quantity",
    QUANTITY_REMANING: "Quantity Remaining",
    DATE_OR_REQUEST: "Requested Date",
    SUBTITUTED_ITEM_NAME: "Subsitute Name",
    LEAD_TIME: "Lead Time",
}

const dataKey = "data"
const totalKey = "total"

const OrderRequestByItem = ({ sortBy, handleTogglerChange }) => {
    const userType = useSelector(state => state?.userAuth?.user?.type)

    let modalState = useSelector(
        state => state.checkBoxSelect.updateCheckBoxData
    )
    const dispatch = useDispatch()
    const [modalDataList, setModalDataList] = useState([])
    const [isSelectAll, setIsSelectAll] = useState(false)
    // const [searchText, setSearchText] = useState("");
    const [modalDependancy, setModalDependancy] = useState(false)
    const [filters, setFilters] = useState(null)
    const {
        data,
        total,
        page,
        pageSize,
        isFetching,
        searchText,
        request,
        handleSearchChange,
        handlePageClick,
        onChangePageSize,
    } = useLocalPaginatedRequest({
        requestFn: OrderRequestByItemsListing,
        params: {
            ...(filters?.Status && { Status: filters?.Status }),
        },
        deps: [filters, modalDependancy],
        dataKey: dataKey,
        totalKey: totalKey,
    })
    let dataLength = data?.length

    // DISPATCH SELECTED LIST ITEMS
    useEffect(() => {
        dispatch(updateSelectedItemsLength({ data: modalDataList?.length }))
    }, [modalDataList?.length])
    useEffect(() => {
        setIsSelectAll(false)
    }, [data])

    // data update for modal on listing check/uncheck items
    const handleCheckBoxSelect = (e, item) => {
        // item.requiredQuantity = 0
        const dataTemp = updateObjCheckBox(item, modalDataList)
        let filteredData = data.filter(
            el => el.quantity - el.quantityreceived > 0
        )
        isSelectAll &&
            dataTemp.length !== filteredData.length &&
            setIsSelectAll(false)
        !isSelectAll &&
            dataTemp.length === filteredData.length &&
            setIsSelectAll(true)
        setModalDataList(dataTemp)
    }
    // select all handler
    const handleSelectAll = () => {
        let filteredData = data.filter(
            el => el.quantity - el.quantityreceived > 0
        )
        isSelectAll ? setModalDataList([]) : setModalDataList(filteredData)
        setIsSelectAll(!isSelectAll)
    }

    // Create P.O modal close
    const placeOrderModalClose = () => {
        setModalDataList([])
        setIsSelectAll(false)
        dispatch(updatePlaceOrderButton({ type: CHECK_BOX_UPDATE_MODAL_ITEMS }))
    }

    // API hit on modal submit data
    const modalDependancyHandler = () => {
        setModalDependancy(!modalDependancy)
    }

    return (
        <>
            <div className="">
                <div className="">
                    <div className=" d-flex justify-content-between">
                        <Refresh onClick={request} />

                        <SearchBox
                            searchText={searchText}
                            onChange={handleSearchChange}
                            inputClasses="table-main-search-input fm-table-input"
                            labelClass="d-none"
                            placeholder="Search"
                            searchIcon={true}
                            searchIconClass="fm-table-search-icon"
                            conClass="fm-table-search-con"
                        />
                    </div>
                </div>
            </div>

            <DataTable
                data={data}
                className="fm-table-con mt-3"
                tableClasses="finance-order-management-order-by-item-table"
                loading={isFetching}
                handleResponsiveForSelectAll="fm-table-selectall finance-form-check"
                config={[
                    {
                        title:
                            userType == UserRoles.Finance
                                ? COLUMNS.SELECT_ALL
                                : "",
                        selectAll: Boolean(userType == UserRoles.Finance),
                        selectAllClickHandler: handleSelectAll,
                        isSelectAll: isSelectAll,
                        isDisabled: !dataLength,
                        render: data => {
                            if (userType != UserRoles.Finance) return

                            return (
                                <div className="w-100 form-check  finance-form-check ">
                                    <TextInput
                                        onClick={e =>
                                            handleCheckBoxSelect(e, data)
                                        }
                                        labelClass="mb-0"
                                        type="checkbox"
                                        inputClass="form-check-input-custom fm-table-checkboxes "
                                        checked={
                                            modalDataList.length > 0
                                                ? modalDataList.filter(
                                                      el => el.id === data.id
                                                  ).length
                                                : false
                                        }
                                        disabled={
                                            data?.quantity -
                                                data?.quantityreceived <=
                                            0
                                        }
                                    />
                                </div>
                            )
                        },
                    },
                    {
                        title: COLUMNS.REQUEST_ID,
                        className: "black-color fm-ftwg-600",
                        render: data => data?.warehouseRequestId,
                    },
                    {
                        title: COLUMNS.SKU,
                        className: "black-color fm-ftwg-600",
                        render: data => data?.skuNo,
                    },
                    {
                        title: COLUMNS.ITEM_NAME,
                        className: "",
                        render: data => {
                            return data?.itemName || "-"
                        },
                    },

                    {
                        title: COLUMNS.QUANTITY,
                        render: data => data?.quantity,
                    },
                    {
                        title: COLUMNS.QUANTITY_REMANING,
                        render: data =>
                            (data?.quantity - data?.quantityreceived)?.toFixed(
                                2
                            ),
                    },

                    {
                        title: COLUMNS.SUBTITUTED_ITEM_NAME,
                        render: data => {
                            return data?.substitutename || "-"
                        },
                    },

                    {
                        title: COLUMNS.DATE_OR_REQUEST,
                        render: data => {
                            return convertDateTime({
                                date: data.createdAt,
                                customFormat:
                                    dateTimeFormat.casitaCustomDateFormat,
                                dateOnly: true,
                            })
                        },
                    },
                    {
                        title: COLUMNS.LEAD_TIME,
                        render: data => data?.itemLeadTtime || "-",
                    },
                    {
                        title: COLUMNS.PREFERRED_VENDOR,
                        render: data => data?.vendorName || "-",
                    },
                ]}
            />
            <Pagination
                currentPage={page}
                totalData={total}
                onPageClick={handlePageClick}
            />
            <Modal
                isOpen={modalState}
                handleModalToggling={placeOrderModalClose}
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                headerClasses="pb-0"
                className="inv-req-popup-viewdetails"
                // size="xl"
                sizeClasses="10"
            >
                <PlaceOrderModal
                    modalCloseHandler={placeOrderModalClose}
                    data={modalDataList}
                    title="Order Details"
                    footerBtnType="Save Order Details"
                    dependancyAPIHandler={modalDependancyHandler}
                />
            </Modal>
        </>
    )
}

export default OrderRequestByItem

import React from "react"
import { Toaster } from "react-hot-toast"
import PropTypes from "prop-types"

import {
    Switch,
    BrowserRouter as Router,
    Route,
    Redirect,
} from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { publicRoutes } from "./routes"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import { DefaultLayout } from "containers"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { store } from "./store"

const App = props => {
    const userType = store?.getState()?.userAuth?.user?.Type

    function getLayout() {
        let layoutCls = VerticalLayout

        return layoutCls
    }

    return (
        <React.Fragment>
            <Router>
                <Switch>
                    {publicRoutes.map((route, idx) => (
                        <Route
                            key={idx}
                            path={route.path}
                            render={props => (
                                <NonAuthLayout>
                                    <route.component {...props} />
                                </NonAuthLayout>
                            )}
                        />
                    ))}
                    <Route
                        path="/"
                        render={props => (
                            <DefaultLayout {...props} Layout={getLayout()} />
                        )}
                    />
                    <Redirect to="/login" />
                </Switch>
            </Router>
            <ToastContainer
                toastClassName="toast-container"
                position="bottom-right"
                autoClose={2500}
                hideProgressBar
                closeOnClick
                draggable
                pauseOnHover
                closeButton={false}
            />
            {/* New toaster */}
            <Toaster
                position="top-right"
                reverseOrder={false}
                toastOptions={{
                    className: "",
                    duration: 3000,
                    success: {
                        duration: 3000,
                    },
                }}
            />
        </React.Fragment>
    )
}

App.propTypes = {
    layout: PropTypes.any,
}

const mapStateToProps = state => {
    return {
        layout: state.Layout,
    }
}

export default connect(mapStateToProps, null)(App)

import React, { useState } from "react"
import { CardBody, Card, Row, Col } from "reactstrap"
import { toast } from "react-hot-toast"
import { useSelector } from "react-redux"

import { DataTable } from "components/DataTable"
import PencilIcon from "assets/images/warehouse/inventory-management/pencil-icon.png"
import ActionEyeIcon from "assets/images/inventory-request/action-eye-icon.png"
import DeleteIcon from "assets/images/inventory/delete-icon.png"
import { SearchBox } from "components/SearchBox"
import { Pagination } from "components/Pagination"
import { Modal } from "components/Modal"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import {
    AddNewItem,
    GetItemsListing,
    UpdateItem,
    CSVFileUpload,
    DeleteInventoryItem,
    AddSelectedItem,
    UpdateSelectedItem,
} from "api/api.service"
import AddNewItemForm from "./AddNewItemForm"
import { Button } from "components/Button"
import MultiAddInventoryModal from "./MultiAddInventoryModal"
import UploadInvFileModal from "./UploadInvFileModal"
import DeleteModal from "components/Common/DeleteModal"
import { store } from "../../../store/index"
import { UserRoles } from "./../../../constants"
import UploadInvFileListingModal from "./UploadInvFileListingModal"
import SelectedItemsModal from "./SelectedItemsModal "
import UpdateSelectedItemsModal from "./UpdateSelectedItemsModal"
import UpdateSelectedItemsListingModal from "./UpdateSelectedItemsListingModal"
import { TextInput } from "components/TextInput"

const DATA_KEY = "data"
const TOTAL_KEY = "total"

const COLUMNS = {
    CHECK_BOXES: "Select",
    SKU_NUMBER: "SKU No. ",
    ITEM_NAME: "Item Name",
    ITEM_TYPE: "Item Type",
    ITEM_QTY: "Item Qty",
    ACTION: "Action",
}
const ACTION_TYPES = {
    VIEW_DETAILS: "VIEW_DETAILS",
    EDIT: "EDIT",
    DELETE_ITEM: "DELETE_ITEM",
}

const ADD = "add"
const EDIT = "edit"

const MIN_LENGTH_OF_ITEMS = 1

const SYNCHRONIZE_TYPE = "synchronizeType"
const IMPORT = "Import"
const AUDIT = "Audit"

const ItemsListing = ({}) => {
    const userType = useSelector(state => state?.userAuth?.user?.type)
    // const [modalToggle, setModalToggle] = useState(false)
    const ourStore = store.getState()
    let currentUserRole = ourStore.userAuth.user.type
    const [isLoading, setIsLoading] = useState(false)
    const [isFileSubmitLoading, setIsFileSubmitLoading] = useState(false)
    const [selectedItem, setSelectedItem] = useState("")
    const [isEdit, setIsEdit] = useState(false)
    const [isReadOnly, setIsReadOnly] = useState(false)
    const [csvQuantities, setCsvQuantities] = useState({})
    const [uploadFileListingData, setUploadFileListingData] = useState({
        items: [],
    })
    const [selectedItems, setSelectedItems] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [actionType, setActionType] = useState(null)
    const [modalStates, setModalStates] = useState({
        modalToggle: false,
        multiAddInventoryModal: false,
        invFileUploadModal: false,
        invFileUploadListingModal: false,
        selectedItemsModal: false,
        editSelectedItemsModal: false,
        isDeleteItemModal: false,
    })
    const isSuperAdmin = userType == UserRoles.SuperAdmin
    const {
        data,
        total,
        isFetching,
        page,
        searchText,
        pageSize,
        handleSearchChange,
        handlePageClick,
        onChangePageSize,
        request,
    } = useLocalPaginatedRequest({
        requestFn: GetItemsListing,
        params: {},
        deps: [],
        dataKey: DATA_KEY,
        totalKey: TOTAL_KEY,
    })

    const handleCloseModal = () => {
        setModalStates({
            ...modalStates,
            modalToggle: false,
            multiAddInventoryModal: false,
            isDeleteItemModal: false,
            invFileUploadModal: false,
            invFileUploadListingModal: false,
            selectedItemsModal: false,
            editSelectedItemsModal: false,
        })
        setIsReadOnly(false)
        setSelectedItem("")
        setIsEdit(false)
    }

    const handleMultiAddInvModalClose = () => {
        setModalStates({ ...modalStates, multiAddInventoryModal: false })
    }

    // Add Inventory Modal open handler
    const addInventoryModalOpen = () => {
        setModalStates({
            ...modalStates,
            multiAddInventoryModal: false,
            modalToggle: true,
        })
    }

    const handleInvFileUploadModalOpen = () => {
        setModalStates({
            ...modalStates,
            multiAddInventoryModal: false,
            invFileUploadModal: true,
        })
    }

    const handleInvFileUploadModalClose = () => {
        setModalStates({
            ...modalStates,
            invFileUploadModal: false,
        })
    }

    const handleInvFileUploadListingModalClose = () => {
        setModalStates({
            ...modalStates,
            invFileUploadListingModal: false,
        })
    }

    const fileSubmitHandler = async fileUploaded => {
        let payload = new FormData()
        payload.append("file", fileUploaded)

        if (actionType == ADD) {
            payload.append(SYNCHRONIZE_TYPE, IMPORT)
        } else if (actionType == EDIT) {
            payload.append(SYNCHRONIZE_TYPE, AUDIT)
        }

        try {
            setIsFileSubmitLoading(true)
            const res = await CSVFileUpload(payload)
            setModalStates({
                ...modalStates,
                invFileUploadModal: false,
                ...(isSuperAdmin && { invFileUploadListingModal: true }),
            })
            setUploadFileListingData(res)
            if (!isSuperAdmin) {
                handleCloseModal()
                request()
            }
        } catch (err) {
        } finally {
            setIsFileSubmitLoading(false)
        }
    }

    const handleSelectItem = (action, data) => {
        if (action == ACTION_TYPES.VIEW_DETAILS) {
            setSelectedItem(data)
            setModalStates({
                ...modalStates,
                modalToggle: true,
            })
            setIsReadOnly(true)
            setIsEdit(false)
        } else if (action == ACTION_TYPES.EDIT) {
            setSelectedItem(data)
            setModalStates({
                ...modalStates,
                modalToggle: true,
            })
            setIsEdit(true)
            setIsReadOnly(false)
        } else if (ACTION_TYPES.DELETE_ITEM) {
            setSelectedItem(data)
            setModalStates({
                ...modalStates,
                isDeleteItemModal: true,
            })
            setIsReadOnly(true)
            setIsEdit(false)
        }
    }

    const handleAddItem = async payload => {
        if (isReadOnly) {
            handleCloseModal()
            return
        }
        try {
            setIsLoading(true)
            const response = await AddNewItem(payload)
            toast.success("Item has been added successfully.")
            handleCloseModal()
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setIsLoading(false)
        }
    }

    const handleUpdateItem = async (id, payload) => {
        try {
            setIsLoading(true)
            const response = await UpdateItem(id, payload)
            toast.success("Item has been updated successfully.")
            handleCloseModal()
            setSelectedRows([])
            request()
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setIsLoading(false)
        }
    }

    const handleSubmit = async formData => {
        let id = formData.id
        let payload = {
            itemName: formData.itemName,
            skuNo: formData.skuNo,
            itemType: formData.itemType,
            description: formData.description,
            majorThreshold: Math.abs(formData.mediumThreshold),
            minorThreshold: Math.abs(formData.minorThreshold),
            mediumThreshold: Math.abs(formData.mediumThreshold),
            itemsCategoryId: formData.itemsCategoryId,
            vendorId: formData.itemsVendorId,
            quantity: formData.quantity,
        }

        if (isEdit) {
            await handleUpdateItem(id, payload)
        } else {
            await handleAddItem(payload)
        }

        !isReadOnly && request()
    }

    const handleDeleteItem = async () => {
        try {
            setIsLoading(true)
            let response = await DeleteInventoryItem(selectedItem.id)
            toast.success("Item has been deleted successfully!")
            request()
            handleCloseModal()
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setIsLoading(false)
        }
    }

    const handleSelectedItemsModalClose = () => {
        setModalStates({ ...modalStates, selectedItemsModal: false })
    }

    const handleEditSelectedItemsModalClose = () => {
        setModalStates({ ...modalStates, editSelectedItemsModal: false })
    }
    const handleDeleteModalClose = () => {
        setModalStates({ ...modalStates, isDeleteItemModal: false })
    }

    const selectedItemUpdateHandler = async formData => {
        const formattedData = selectedRows.map(item => ({
            itemsId: item.id,
            quantity: Number(csvQuantities[item.id]) || 0,
        }))

        const payload = {
            items: formattedData,
            adjustmentNote: formData.note,
        }

        try {
            setIsLoading(true)
            await UpdateSelectedItem(payload)
            toast.success("Selected Item has been updated successfully.")
            handleCloseModal()
            request()
            setSelectedRows([])
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setIsLoading(false)
        }
    }

    const updateFileUploadListingHandler = async formData => {
        const formattedData = uploadFileListingData?.items?.map(item => ({
            id: item.id,
            skuNo: item.skuNo,
            existingQuantity: item.existingItemQuantity || 0,
            newQuantity: Number(item.newQuantity) || 0,
            adjustmentQuantity: item.adjustmentQuantity || 0,
        }))
        const payload = {
            items: formattedData,
            bulkImportId: uploadFileListingData.bulkImportId,
            synchronizeType: AUDIT,
            adjustmentNote: formData.note,
        }

        try {
            setIsLoading(true)
            await AddSelectedItem(payload)
            toast.success("Selected Item has been updated successfully.")
            handleCloseModal()
            request()
            setSelectedRows([])
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setIsLoading(false)
        }
    }

    const selectedItemHandler = async () => {
        const formattedData = selectedItems?.map(item => ({
            id: item.id,
            skuNo: item.skuNo,
            existingQuantity: item.existingItemQuantity || 0,
            newQuantity: Number(item.newQuantity) || 0,
            adjustmentQuantity: item.adjustmentQuantity || 0,
            price: item.price,
        }))
        const payload = {
            items: formattedData,
            bulkImportId: uploadFileListingData.bulkImportId,
            synchronizeType: IMPORT,
        }
        try {
            setIsLoading(true)
            await AddSelectedItem(payload)
            toast.success("Selected Item has been added successfully.")
            handleCloseModal()
            request()
            setSelectedRows([])
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setIsLoading(false)
        }
    }

    const handleNextButtonClick = selectedItems => {
        setSelectedItems(selectedItems)
        setModalStates(prev => ({
            ...prev,
            selectedItemsModal: true,
        }))
    }

    const handleBackButtonClick = () => {
        setModalStates({
            invFileUploadListingModal: true,
            selectedItemsModal: false,
        })
    }

    const updateModalStates = type => {
        setActionType(type)
        setModalStates({
            ...modalStates,
            ...(selectedRows.length > 0
                ? { editSelectedItemsModal: true }
                : { invFileUploadModal: true }),
        })
    }

    const handleCheckedBoxSelection = item => {
        setSelectedRows(prevSelectedItems => {
            if (prevSelectedItems.find(selected => selected.id === item.id)) {
                return prevSelectedItems.filter(
                    selected => selected.id !== item.id
                )
            } else {
                return [...prevSelectedItems, item]
            }
        })
    }

    const isItemSelected = item => {
        return (
            selectedRows?.find(selected => selected.id == item.id) !== undefined
        )
    }

    return (
        <>
            <Card className="mb-5">
                <CardBody className="p-2 p-md-4 wh-im-wh-gi-con">
                    <div className="inv-item-listing-header  mb-2 mb-md-3">
                        <div className="inv-item-listing-header-btn-con">
                            <Button
                                size="md"
                                title="Add Item"
                                className="gt-btn-grad-primary add-inv-btn me-0 me-md-2 my-sm-2 my-md-0 wh-im-dpgi-status ft-table-header inv-item-listing-header-btn"
                                onClick={() => {
                                    setActionType(ADD)
                                    setModalStates({
                                        ...modalStates,
                                        multiAddInventoryModal: true,
                                    })
                                }}
                            />
                            {isSuperAdmin && (
                                <Button
                                    size="md"
                                    title="Edit Qty"
                                    className="gt-btn-grad-primary add-inv-btn me-0 me-md-2 wh-im-dpgi-status ft-table-header inv-item-listing-header-btn"
                                    onClick={() => updateModalStates(EDIT)}
                                    disabled={total < MIN_LENGTH_OF_ITEMS}
                                />
                            )}
                        </div>

                        <div className="inv-item-listing-header-search-box ad-finance-search-box-contanier">
                            <SearchBox
                                inputClasses="vendor-search-input input-search gi-im-search-ip"
                                labelClass="d-none"
                                placeholder="Search"
                                searchIcon={true}
                                searchIconClass="vendor-table-search-icon"
                                conClass="vendor-table-search-inner-class"
                                searchText={searchText}
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>

                    <DataTable
                        data={data}
                        loading={isFetching}
                        tableClasses="finance-im-wh-chemicals"
                        config={(function () {
                            var arr = [
                                {
                                    title: COLUMNS.SKU_NUMBER,
                                    render: data => {
                                        return <>{data?.skuNo || "-"}</>
                                    },
                                },
                                {
                                    title: COLUMNS.ITEM_NAME,
                                    render: data => {
                                        return data?.itemName || "-"
                                    },
                                },
                                {
                                    title: COLUMNS.ITEM_TYPE,
                                    render: data => {
                                        return data?.itemType || "-"
                                    },
                                },
                                {
                                    title: COLUMNS.ITEM_QTY,
                                    render: data => data?.globalQuantity,
                                },

                                {
                                    title: COLUMNS.ACTION,
                                    render: data => (
                                        <>
                                            <span className="text-success pointer d-flex justify-content-center align-items-center">
                                                <img
                                                    style={{
                                                        height: "18px",
                                                        width: "23px",
                                                    }}
                                                    src={PencilIcon}
                                                    onClick={() =>
                                                        handleSelectItem(
                                                            ACTION_TYPES.EDIT,
                                                            data
                                                        )
                                                    }
                                                />
                                                <img
                                                    style={{
                                                        height: "18px",
                                                        width: "23px",
                                                    }}
                                                    src={ActionEyeIcon}
                                                    className="mx-2"
                                                    onClick={() =>
                                                        handleSelectItem(
                                                            ACTION_TYPES.VIEW_DETAILS,
                                                            data
                                                        )
                                                    }
                                                />
                                                {[
                                                    UserRoles.Admin,
                                                    UserRoles.SuperAdmin,
                                                    UserRoles.Executive,
                                                ].includes(currentUserRole) && (
                                                    <img
                                                        style={{
                                                            height: "17px",
                                                            width: "13px",
                                                        }}
                                                        src={DeleteIcon}
                                                        onClick={() =>
                                                            handleSelectItem(
                                                                ACTION_TYPES.DELETE_ITEM,
                                                                data
                                                            )
                                                        }
                                                    />
                                                )}
                                            </span>
                                        </>
                                    ),
                                },
                            ]

                            if (isSuperAdmin) {
                                arr.splice(0, 0, {
                                    title: COLUMNS.CHECK_BOXES,
                                    render: data => (
                                        <div className=" ">
                                            <TextInput
                                                onClick={() =>
                                                    handleCheckedBoxSelection(
                                                        data
                                                    )
                                                }
                                                labelClass="mb-0"
                                                type="checkbox"
                                                inputClass="form-check-input-custom pointer"
                                                checked={isItemSelected(data)}
                                            />
                                        </div>
                                    ),
                                })
                            }
                            return arr
                        })()}
                    />

                    <Pagination
                        currentPage={page}
                        totalData={total}
                        onPageClick={handlePageClick}
                        rowLimit={pageSize}
                    />
                </CardBody>
            </Card>
            <Modal
                isOpen={modalStates.modalToggle}
                handleModalToggling={handleCloseModal}
                bodyClassName="px-4"
                customButton={true}
                hideModalHeaderSeparator={true}
                sizeClasses="10"
            >
                <AddNewItemForm
                    isNative
                    isReadOnly={isReadOnly}
                    modalCloseHandler={handleCloseModal}
                    title={
                        isEdit
                            ? "Update Item"
                            : isReadOnly
                            ? "Item Details"
                            : "Add New Item"
                    }
                    footerBtnType="Done"
                    handleFormSubmit={handleSubmit}
                    isLoading={isLoading}
                    data={selectedItem}
                    isEditing={isEdit}
                />
            </Modal>
            <Modal
                isOpen={modalStates.multiAddInventoryModal}
                handleModalToggling={handleMultiAddInvModalClose}
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                headerClasses="pb-0"
                className="inv-req-popup-viewdetails"
                size="sm"
            >
                <MultiAddInventoryModal
                    modalCloseHandler={handleMultiAddInvModalClose}
                    handleInvFileUploadModalOpen={handleInvFileUploadModalOpen}
                    addInventoryModalOpen={addInventoryModalOpen}
                    title="Add Items"
                />
            </Modal>
            <Modal
                isOpen={modalStates.invFileUploadModal}
                handleModalToggling={handleInvFileUploadModalClose}
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                headerClasses="pb-0"
                className="inv-req-popup-viewdetails"
                size="sm"
            >
                <UploadInvFileModal
                    modalCloseHandler={handleInvFileUploadModalClose}
                    title="ADD ITEMS"
                    fileSubmitHandler={fileSubmitHandler}
                    isFileSubmitLoading={isFileSubmitLoading}
                />
            </Modal>

            <Modal
                isOpen={modalStates.invFileUploadListingModal}
                handleModalToggling={handleInvFileUploadListingModalClose}
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                // headerClasses="pb-0 "
                size="lg"
            >
                {actionType == ADD && (
                    <UploadInvFileListingModal
                        title={"CSV Inventory"}
                        listingData={uploadFileListingData.items}
                        onNextButtonClick={handleNextButtonClick}
                        loading={isFileSubmitLoading}
                    />
                )}

                {actionType == EDIT && (
                    <UpdateSelectedItemsListingModal
                        title={"Edit Qty"}
                        selectedItems={uploadFileListingData.items}
                        onSend={updateFileUploadListingHandler}
                        loading={isLoading}
                    />
                )}
            </Modal>

            <Modal
                isOpen={modalStates.selectedItemsModal}
                handleModalToggling={handleSelectedItemsModalClose}
                // className="inv-req-popup-viewdetails"
                size="lg"
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                // headerClasses="pb-0"
            >
                <SelectedItemsModal
                    title={"CSV Inventory"}
                    selectedItems={selectedItems}
                    onClose={handleSelectedItemsModalClose}
                    onSend={selectedItemHandler}
                    onBack={handleBackButtonClick}
                    loading={isLoading}
                />
            </Modal>
            <Modal
                isOpen={modalStates.editSelectedItemsModal}
                handleModalToggling={handleEditSelectedItemsModalClose}
                className="inv-req-popup-viewdetails"
                size="lg"
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                // headerClasses="pb-0"
            >
                <UpdateSelectedItemsModal
                    title={"Edit Qty"}
                    selectedItems={selectedRows}
                    onClose={handleEditSelectedItemsModalClose}
                    onSend={selectedItemUpdateHandler}
                    csvQuantities={csvQuantities}
                    setCsvQuantities={setCsvQuantities}
                    loading={isLoading}
                />
            </Modal>

            <Modal
                size="md"
                isOpen={modalStates.isDeleteItemModal}
                ACTION
                modalTitle="Delete Item"
                handleModalToggling={handleDeleteModalClose}
            >
                <Row>
                    <Col lg={12}>
                        <div className="text-center">
                            <i
                                className="mdi mdi-alert-circle-outline"
                                style={{ fontSize: "9em", color: "orange" }}
                            />
                            <h2>Are you sure?</h2>
                            <h4>{"You won't be able to revert this!"}</h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="text-center mt-3">
                            <button
                                type="button"
                                className="btn btn-danger btn-lg ms-2"
                                onClick={handleDeleteItem}
                            >
                                Yes, delete it!
                            </button>
                            <button
                                type="button"
                                className="btn btn-success btn-lg ms-2"
                                onClick={handleCloseModal}
                            >
                                Cancel
                            </button>
                        </div>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default ItemsListing

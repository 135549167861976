import React, { useState, useEffect, useRef } from "react"
import { Col, Row, Form, Input, FormGroup, Label } from "reactstrap"
import { useFormik } from "formik"
import toast from "react-hot-toast"
import { useParams } from "react-router-dom"

import { Modal } from "../../../components/Modal"
import { TextInput } from "../../../components/TextInput"
import { Button } from "../../../components/Button"
import FormikErrorText from "../../../components/FormikErrorText/FormikErrorText"
import ImageGallery from "../CommonUI/ImageGallery/ImageGallery"
import {
    GetEquipmentCategoryItemDetails,
    AddEquipmentCategoryItems,
    UpdateEquipmentCategoryItems,
} from "../../../api/api.service"
import {
    initializeMediaUtil,
    uploadOnS3,
    finalizeMediaUtil,
} from "../../../utils/mediaUtils"
import {
    ADD_NOTE,
    FIELDS_NAME,
    VARIATIONS,
    initialValues,
    validationSchema,
} from "./FormConfig"
import ActiveInactiveSwitch from "../CommonUI/ActiveInactiveSwitch/ActiveInactiveSwitch"
import { convertToSelectOptions, getUID } from "utils/commonUtils"
import {
    EquipmentCategoryItemsStatus,
    ConfiguratorEquipmentItemSelectionType,
} from "../../../constants"
import crossIcon from "../../../assets/images/cross-red.png"
import addIcon from "../../../assets/images/add-icon.svg"
import FormSkeleton from "components/FormSkeleton/FormSkeleton"

const TITLES = {
    MODAL_ADD_TITLE: "Add new item",
    MODAL_UPDATE_TITLE: "Update item",
    MODAL_DETAILS_TITLE: "Item details",
    ADD_BTN_TEXT: "Add item",
    UPDATE_BTN_TEXT: "Update item",
    DETAILS_BTN_TEXT: "Done",
}

const handleStatusConversion = status => {
    return status == EquipmentCategoryItemsStatus.Active ? true : false
}

const handleUpdateExecution = async (id, data) => {
    let res = await UpdateEquipmentCategoryItems(id, data)
    toast.success("Item has been updated successfully !")
}

const STRUCTURE_VARIATION_ITEM = {
    localId: "",
    dbId: "",
    itemName: {
        name: "",
        value: "",
        errorMsg: "",
    },
    itemPrice: {
        name: "",
        value: "",
        errorMsg: "",
    },
}

const checkRemoveVariationItemEligibility = (variations, variationItems) => {
    let MIN_LENGTH_OF_VARIATION_RECORD = 1
    return variations == VARIATIONS.Yes &&
        Object.keys(variationItems).length == MIN_LENGTH_OF_VARIATION_RECORD
        ? "none"
        : "auto"
}

const ManageEquipmentCategoryItem = ({
    isDetailView = false,
    isOpen = false,
    onToggleModal = () => {},
    onSuccess = () => {},
    id = "",
}) => {
    // Custom fields which are not handled directly by formik
    const [variationItems, setVariationItems] = useState({
        [getUID()]: {
            ...STRUCTURE_VARIATION_ITEM,
        },
    })
    const [variationType, setVariationType] = useState(
        ConfiguratorEquipmentItemSelectionType.Single
    )
    const [variations, setVariations] = useState(VARIATIONS.No)
    const [addNote, setAddNote] = useState(ADD_NOTE.No)
    const [status, setStatus] = useState(true)
    const imagesRef = useRef(null)
    const { trailerId, categoryId } = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [previousImages, setPreviousImages] = useState([])
    const [isFetching, setIsFetching] = useState(false)

    const formik = useFormik({
        enableReinitialze: true,
        initialValues: {
            ...initialValues,
        },
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values)
        },
    })

    useEffect(() => {
        handleFetchDetails()
        return () => {
            setIsLoading(false)
        }
    }, [id])

    const handleSubmit = values => {
        var bodyData = {
            ...(!id && { configuratorEquipmentSubTypeId: Number(categoryId) }),
            name: values[FIELDS_NAME.NAME],
            description: values[FIELDS_NAME.DESCRIPTION],
            status: values[FIELDS_NAME.STATUS],
            price: values[FIELDS_NAME.PRICE].toString(),
            addNote: Boolean(values[FIELDS_NAME.ADD_NOTE] == ADD_NOTE.Yes),
            variationFlag: Boolean(
                values[FIELDS_NAME.VARIATION] == VARIATIONS.Yes
            ),
        }

        if (values[FIELDS_NAME.VARIATION] == VARIATIONS.Yes) {
            bodyData = {
                ...bodyData,
                selectionType: values[FIELDS_NAME.VARIATION_TYPE],
                subTypePropertyVariations: (function () {
                    const arr = []

                    for (const key in variationItems) {
                        arr.push({
                            name: variationItems[key].itemName.value,
                            price: variationItems[key].itemPrice.value,
                        })
                    }
                    return arr
                })(),
            }
        } else if (values[FIELDS_NAME.VARIATION] == VARIATIONS.No) {
            bodyData = {
                ...bodyData,
                subTypePropertyVariations: [],
            }
        }

        if (id) {
            handleUpdate(bodyData)
        } else {
            handleAdd(bodyData)
        }
    }

    const uploadedMediaIDs = async images => {
        if (images) {
            try {
                let res = await handleUploadImages(images)
                return res?.length && res.map(item => item.id)
            } finally {
            }
        }
    }

    const handleUpdate = async data => {
        const payload = {
            ...data,
        }

        let userUploadedImages = imagesRef.current.getAllImages()

        if (!userUploadedImages.length && !previousImages.length) {
            return toast.error("Upload item images !")
        }

        try {
            setIsLoading(true)

            if (userUploadedImages.length) {
                uploadedMediaIDs(userUploadedImages).then(async res => {
                    const apiFormatForImages = res.map(imageId => ({
                        mediaId: imageId,
                    }))
                    payload.images = [
                        ...(apiFormatForImages?.length && apiFormatForImages),
                        ...(previousImages?.length
                            ? previousImages.map(image => ({
                                  mediaId: image.media.id,
                              }))
                            : []),
                    ]

                    await handleUpdateExecution(id, payload)
                    handleModalClose()
                    onSuccess()
                })
            } else {
                payload.images = [
                    ...(previousImages?.length &&
                        previousImages.map(image => ({
                            mediaId: image.mediaId,
                        }))),
                ]

                await handleUpdateExecution(id, payload)
                handleModalClose()
                onSuccess()
            }
        } finally {
            setIsLoading(true)
        }
    }
    const handleAdd = async data => {
        let userUploadedImages = imagesRef.current.getAllImages()

        if (!userUploadedImages.length) {
            toast.error("Upload item images !")
            return
        }

        try {
            setIsLoading(true)
            let ids = await uploadedMediaIDs(userUploadedImages)
            let apiFormatForImages = ids.map(imageId => ({
                mediaId: imageId,
            }))
            data.images = [...(apiFormatForImages.length && apiFormatForImages)]

            let res = await AddEquipmentCategoryItems(data)
            toast.success("Item has been added successfully !")
            onSuccess()
            handleModalClose()
        } catch (error) {
        } finally {
            setIsLoading(false)
        }
    }

    const handleFetchDetails = async () => {
        if (!id) return

        try {
            setIsFetching(true)
            let res = await GetEquipmentCategoryItemDetails(id)

            formik.setValues({
                [FIELDS_NAME.NAME]: res?.name,
                [FIELDS_NAME.DESCRIPTION]: res?.description,
                [FIELDS_NAME.STATUS]: res.status,
                [FIELDS_NAME.PRICE]: res.price,
                [FIELDS_NAME.VARIATION]: res?.variationFlag
                    ? VARIATIONS.Yes
                    : VARIATIONS.No,
                [FIELDS_NAME.VARIATION_TYPE]: res.selectionType,
                [FIELDS_NAME.ADD_NOTE]: res.addNote
                    ? ADD_NOTE.Yes
                    : ADD_NOTE.No,
            })
            setStatus(handleStatusConversion(res.status))
            setAddNote(res.addNote ? ADD_NOTE.Yes : ADD_NOTE.No)
            setVariationType(res.selectionType)
            setVariations(res.variationFlag ? VARIATIONS.Yes : VARIATIONS.No)
            setPreviousImages(res?.images)
            if (res?.subTypePropertyVariations?.length) {
                let obj = {}

                res.subTypePropertyVariations.forEach((item, indx) => {
                    let id = getUID()

                    obj = {
                        ...obj,

                        [id]: {
                            localId: "",
                            dbId: item.id,
                            itemName: {
                                name: "",
                                value: item.name,
                                errorMsg: "",
                            },
                            itemPrice: {
                                name: "",
                                value: item.price,
                                errorMsg: "",
                            },
                        },
                    }
                })
                setVariationItems(obj)
            }
        } finally {
            setIsFetching(false)
        }
    }

    const handleUploadImages = async images => {
        let apiCallIns
        let uploadedIds = []

        apiCallIns = images.map(item => {
            return handleMediaUpload(item)
        })

        try {
            setIsLoading(true)
            uploadedIds = (await Promise.allSettled(apiCallIns))
                .filter(item => item.status === "fulfilled")
                .map(id => id.value)
        } finally {
            setIsLoading(false)
        }

        return uploadedIds
    }

    const handleMediaUpload = async file => {
        return initializeMediaUtil(file).then(async res => {
            const credentials = res
            await uploadOnS3(file, credentials, handleImageUploadProgress)
            return await finalizeMediaUtil(credentials?.mediaId)
        })
    }

    const handleImageUploadProgress = progress => {}

    const getOptions = (optionsEntity = {}) => {
        return convertToSelectOptions(optionsEntity).map((item, ind) => {
            return (
                <option value={item.value} key={ind}>
                    {item.label}
                </option>
            )
        })
    }

    const handleModalClose = () => {
        formik.resetForm()
        setPreviousImages([])
        setAddNote(ADD_NOTE.No)
        setStatus(true)
        setVariationType(ConfiguratorEquipmentItemSelectionType.Single)
        setVariations(VARIATIONS.No)
        setVariationItems({
            [getUID()]: {
                ...STRUCTURE_VARIATION_ITEM,
            },
        })

        onToggleModal()
    }
    const handleSetStatus = evt => {
        let { name } = evt.target
        let alteredStatus = !status
        setStatus(alteredStatus)
        formik.setFieldValue(
            name,
            alteredStatus
                ? EquipmentCategoryItemsStatus.Active
                : EquipmentCategoryItemsStatus.Inactive
        )
    }

    const handleSetVariations = evt => {
        let { name, value } = evt.target
        setVariations(value)
        formik.setFieldValue(name, value)
    }

    const handleSetVariationType = evt => {
        let { name, value } = evt.target
        setVariationType(value)
        formik.setFieldValue(name, value)
    }
    const handleSetAddNote = evt => {
        let { name, value } = evt.target
        setAddNote(value)
        formik.setFieldValue(name, value)
    }

    const handleAddItemInVariations = () => {
        const id = getUID()

        setVariationItems(prev => ({
            ...prev,
            [id]: {
                ...STRUCTURE_VARIATION_ITEM,
            },
        }))
    }

    const handleRemoveVariation = id => {
        let cloneOfCurrentList = { ...variationItems }
        delete cloneOfCurrentList[id]
        setVariationItems(cloneOfCurrentList)
    }

    const handleSetVariationItemName = (evt, id) => {
        setVariationItems(prev => ({
            ...prev,
            [id]: {
                ...prev[id],
                itemName: {
                    ...prev[id]["itemName"],
                    value: evt.target.value,
                },
            },
        }))
    }

    const handleSetVariationItemPrice = (evt, id) => {
        setVariationItems(prev => ({
            ...prev,
            [id]: {
                ...prev[id],
                itemPrice: {
                    ...prev[id]["itemPrice"],
                    value: evt.target.value,
                },
            },
        }))
    }

    useEffect(() => {
        console.log(formik)
    }, [formik])

    return (
        <Modal
            isOpen={isOpen}
            handleModalToggling={handleModalClose}
            bodyClassName=""
            customButton={true}
            hideModalHeaderSeparator={true}
            headerClasses="header-container"
            sizeClasses="10"
            backdrop="static"
            modalTitle={
                isDetailView
                    ? TITLES.MODAL_DETAILS_TITLE
                    : id
                    ? TITLES.MODAL_UPDATE_TITLE
                    : TITLES.MODAL_ADD_TITLE
            }
            scrollable
        >
            {isFetching ? (
                <FormSkeleton />
            ) : (
                <div style={{ pointerEvents: isLoading ? "none" : "auto" }}>
                    <Row>
                        <Col xs={12}>
                            <Form onSubmit={formik.handleSubmit}>
                                {/* 
                            ------------------
                            Item Name
                            ------------------
                            */}
                                <Row className="mb-3 align-items-center">
                                    <label className=" col-md-3 col-form-label clr-theme-primary">
                                        Item Name
                                    </label>
                                    <div className="col-md-9">
                                        <TextInput
                                            inputClass={
                                                isDetailView && "input-readonly"
                                            }
                                            placeholder="Enter item name"
                                            name={FIELDS_NAME.NAME}
                                            {...formik.getFieldProps(
                                                FIELDS_NAME.NAME
                                            )}
                                        />
                                        <FormikErrorText
                                            formikInstance={formik}
                                            fieldName={FIELDS_NAME.NAME}
                                        />
                                    </div>
                                </Row>

                                {/* 
                            ------------------
                            Item Description
                            ------------------
                            */}
                                <Row className="mb-3 align-items-center">
                                    <label className=" col-md-3 col-form-label clr-theme-primary">
                                        Item Description
                                    </label>
                                    <div className="col-md-9">
                                        {!isDetailView && (
                                            <TextInput
                                                size="md"
                                                type="textarea"
                                                // inputClass={
                                                //     isDetailView && "input-readonly"
                                                // }
                                                placeholder="Enter item description"
                                                name={FIELDS_NAME.DESCRIPTION}
                                                {...formik.getFieldProps(
                                                    FIELDS_NAME.DESCRIPTION
                                                )}
                                            />
                                        )}
                                        {isDetailView && (
                                            <p
                                                style={{
                                                    wordWrap: "break-word",
                                                    width: "100%",
                                                    fontSize: "12px",
                                                    marginLeft: "6px",
                                                }}
                                            >
                                                {formik.values[
                                                    FIELDS_NAME.DESCRIPTION
                                                ].trim()}
                                            </p>
                                        )}

                                        <FormikErrorText
                                            formikInstance={formik}
                                            fieldName={FIELDS_NAME.DESCRIPTION}
                                        />
                                    </div>
                                </Row>

                                {/* 
                            ------------------
                            Item Price
                            ------------------
                            */}

                                <Row className="mt-4 mb-3 align-items-center">
                                    <label className=" col-md-3 col-form-label clr-theme-primary">
                                        Price ($)
                                    </label>
                                    <div className="col-md-9">
                                        <TextInput
                                            type="number"
                                            min={0}
                                            step="0.000001"
                                            inputClass={
                                                isDetailView && "input-readonly"
                                            }
                                            placeholder="Enter item price"
                                            name={FIELDS_NAME.PRICE}
                                            {...formik.getFieldProps(
                                                FIELDS_NAME.PRICE
                                            )}
                                        />

                                        <FormikErrorText
                                            formikInstance={formik}
                                            fieldName={FIELDS_NAME.PRICE}
                                        />
                                    </div>
                                </Row>

                                {/* 
                            ------------------
                            Upload Images
                            ------------------
                            */}

                                <Row className="mt-4 mb-3 align-items-center">
                                    <label className=" col-md-3 col-form-label clr-theme-primary">
                                        Upload Images
                                    </label>
                                    <div className="col-md-9">
                                        <ImageGallery
                                            ref={imagesRef}
                                            previousImages={previousImages}
                                            removePreviousImages={
                                                setPreviousImages
                                            }
                                            previewOnly={isDetailView}
                                            isDetailView={isDetailView}
                                        />
                                    </div>
                                </Row>
                                {/* 
                            ------------------
                            Variations
                            ------------------
                            */}
                                <Row className="mt-4 mb-3 align-items-center">
                                    <label className=" col-md-3 col-form-label">
                                        Variations
                                    </label>
                                    <div className="col-md-9">
                                        <div className="d-flex gap-2">
                                            <label className="d-flex align-items-center gap-1">
                                                <TextInput
                                                    disabled={isDetailView}
                                                    type="radio"
                                                    name={FIELDS_NAME.VARIATION}
                                                    value={VARIATIONS.Yes}
                                                    checked={
                                                        variations ==
                                                        VARIATIONS.Yes
                                                    }
                                                    onClick={
                                                        handleSetVariations
                                                    }
                                                />
                                                <span>Yes</span>
                                            </label>

                                            <label className="d-flex align-items-center gap-1">
                                                <TextInput
                                                    disabled={isDetailView}
                                                    type="radio"
                                                    name={FIELDS_NAME.VARIATION}
                                                    value={VARIATIONS.No}
                                                    checked={
                                                        variations ==
                                                        VARIATIONS.No
                                                    }
                                                    onClick={
                                                        handleSetVariations
                                                    }
                                                />
                                                <span>No</span>
                                            </label>
                                        </div>
                                    </div>
                                </Row>

                                {/* 
                            -------------------------------
                            Single or Multiple Variation Type
                            -------------------------------
                            */}

                                {variations == VARIATIONS.Yes && (
                                    <Row className="mt-4 mb-3 align-items-center">
                                        <label className=" col-md-3 col-form-label"></label>
                                        <div className="col-md-9">
                                            <div className="d-flex gap-2 ">
                                                <label className="d-flex align-items-center gap-1">
                                                    <TextInput
                                                        disabled={isDetailView}
                                                        type="radio"
                                                        name={
                                                            FIELDS_NAME.VARIATION_TYPE
                                                        }
                                                        value={
                                                            ConfiguratorEquipmentItemSelectionType.Single
                                                        }
                                                        checked={
                                                            variationType ==
                                                            ConfiguratorEquipmentItemSelectionType.Single
                                                        }
                                                        onClick={
                                                            handleSetVariationType
                                                        }
                                                    />
                                                    <span>Single Select</span>
                                                </label>

                                                <label className="d-flex align-items-center gap-1">
                                                    <TextInput
                                                        disabled={isDetailView}
                                                        type="radio"
                                                        name={
                                                            FIELDS_NAME.VARIATION_TYPE
                                                        }
                                                        value={
                                                            ConfiguratorEquipmentItemSelectionType.Multiple
                                                        }
                                                        checked={
                                                            variationType ==
                                                            ConfiguratorEquipmentItemSelectionType.Multiple
                                                        }
                                                        onClick={
                                                            handleSetVariationType
                                                        }
                                                    />
                                                    <span>Multiple Select</span>
                                                </label>
                                            </div>
                                        </div>
                                    </Row>
                                )}
                                {/* 
                            -------------------------------
                            Added Item Field
                            -------------------------------
                            */}
                                {variations == VARIATIONS.Yes && (
                                    <Row className="mb-3">
                                        <label className=" col-md-3 col-form-label clr-theme-primary"></label>
                                        <div
                                            className="col-md-9"
                                            style={{
                                                maxHeight: "200px",
                                                overflowY: "scroll",
                                            }}
                                        >
                                            {/* Input starts */}

                                            {Object.keys(variationItems).map(
                                                (itemId, indx) => {
                                                    return (
                                                        <>
                                                            <div
                                                                className="d-flex align-items-center gap-1 my-2"
                                                                key={indx}
                                                            >
                                                                {!isDetailView && (
                                                                    <img
                                                                        onClick={() =>
                                                                            handleRemoveVariation(
                                                                                itemId
                                                                            )
                                                                        }
                                                                        style={{
                                                                            width: "20px",
                                                                            height: "20px",
                                                                            pointerEvents:
                                                                                checkRemoveVariationItemEligibility(
                                                                                    variations,
                                                                                    variationItems
                                                                                ),
                                                                        }}
                                                                        className="pointer"
                                                                        src={
                                                                            crossIcon
                                                                        }
                                                                    />
                                                                )}
                                                                <div className="w-50">
                                                                    {isDetailView && (
                                                                        <span className="fw-bold">
                                                                            Item
                                                                            Name
                                                                        </span>
                                                                    )}
                                                                    <TextInput
                                                                        required
                                                                        type="text"
                                                                        inputClass={`${
                                                                            isDetailView &&
                                                                            "input-readonly"
                                                                        } w-100`}
                                                                        placeholder="Enter name"
                                                                        value={
                                                                            variationItems[
                                                                                itemId
                                                                            ][
                                                                                "itemName"
                                                                            ]
                                                                                .value
                                                                        }
                                                                        onChange={e =>
                                                                            handleSetVariationItemName(
                                                                                e,
                                                                                itemId
                                                                            )
                                                                        }
                                                                        title="please"
                                                                    />
                                                                </div>

                                                                <div className="w-25">
                                                                    {isDetailView && (
                                                                        <span className="fw-bold">
                                                                            {
                                                                                "Item Price ($)"
                                                                            }
                                                                        </span>
                                                                    )}
                                                                    <TextInput
                                                                        required
                                                                        type={
                                                                            "number"
                                                                        }
                                                                        min={1}
                                                                        inputClass={`${
                                                                            isDetailView &&
                                                                            "input-readonly"
                                                                        } w-100`}
                                                                        placeholder="Enter price"
                                                                        value={
                                                                            variationItems[
                                                                                itemId
                                                                            ][
                                                                                "itemPrice"
                                                                            ]
                                                                                .value
                                                                        }
                                                                        onChange={e =>
                                                                            handleSetVariationItemPrice(
                                                                                e,
                                                                                itemId
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            )}

                                            {/* Input end */}

                                            {!isDetailView && (
                                                <div
                                                    className="add-more-container mt-4 pointer"
                                                    onClick={
                                                        handleAddItemInVariations
                                                    }
                                                >
                                                    <div className="d-flex gap-1 justify-content-start">
                                                        <img
                                                            style={{
                                                                width: "20px",
                                                                height: "20px",
                                                            }}
                                                            className="cursor"
                                                            src={addIcon}
                                                        />
                                                        <span
                                                            style={{
                                                                color: "#1374D5",
                                                            }}
                                                        >
                                                            Add Item
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </Row>
                                )}
                                {/* 
                            -------------------------------
                            Add Note
                            -------------------------------
                            */}

                                <Row className="mt-4 mb-3 align-items-center">
                                    <label className=" col-md-3 col-form-label">
                                        Add Note
                                    </label>
                                    <div className="col-md-9">
                                        <div className="d-flex gap-2">
                                            <label className="d-flex align-items-center gap-1">
                                                <TextInput
                                                    disabled={isDetailView}
                                                    type="radio"
                                                    name={FIELDS_NAME.ADD_NOTE}
                                                    value={ADD_NOTE.Yes}
                                                    checked={
                                                        addNote == ADD_NOTE.Yes
                                                    }
                                                    onClick={handleSetAddNote}
                                                />
                                                <span>Yes</span>
                                            </label>

                                            <label className="d-flex align-items-center gap-1">
                                                <TextInput
                                                    disabled={isDetailView}
                                                    type="radio"
                                                    name={FIELDS_NAME.ADD_NOTE}
                                                    value={ADD_NOTE.No}
                                                    checked={
                                                        addNote == ADD_NOTE.No
                                                    }
                                                    onClick={handleSetAddNote}
                                                />
                                                <span>No</span>
                                            </label>
                                        </div>
                                    </div>
                                </Row>

                                {/* 
                            -------------------------------
                             Status
                            -------------------------------
                            */}
                                <Row className="mb-3 align-items-center">
                                    <label className=" col-md-3 col-form-label clr-theme-primary">
                                        Status
                                    </label>
                                    <div className="col-md-9">
                                        <ActiveInactiveSwitch
                                            isChecked={status}
                                            isDisable={isDetailView}
                                            name={FIELDS_NAME.STATUS}
                                            onChange={handleSetStatus}
                                        />
                                        <FormikErrorText
                                            formikInstance={formik}
                                            fieldName={FIELDS_NAME.STATUS}
                                        />
                                    </div>
                                </Row>

                                <div className="d-flex justify-content-center mt-3 mt-2">
                                    <Button
                                        isLoading={isLoading}
                                        type={
                                            isDetailView ? "button" : "submit"
                                        }
                                        title={
                                            isDetailView
                                                ? TITLES.DETAILS_BTN_TEXT
                                                : id
                                                ? TITLES.UPDATE_BTN_TEXT
                                                : TITLES.ADD_BTN_TEXT
                                        }
                                        className="header-button"
                                        onClick={
                                            isDetailView && handleModalClose
                                        }
                                    />
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </div>
            )}
        </Modal>
    )
}

export default React.memo(ManageEquipmentCategoryItem)
